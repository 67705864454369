import * as angular from 'angular';

class DataTablePagingComponent implements angular.IComponentOptions {
  bindings = {
    page: '=',
    total: '<',
    pageSize: '=',
    hidePageSize: '<',
  };
  controller = DataTablePagingController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/core/datatable-paging.component.html';
}

class DataTablePagingController {
  public page: number;
  public pageSize: number;
  public startRecordNum: number;
  public endRecordNum: number;
  public total: number;
  public maxPage: number;
  public hidePageSize: boolean;

  public pageSizes = [5, 10, 20, 30, 50, 100, 200];

  $onInit() {
    if (!this.pageSize) this.pageSize = 10;
    this.calculateRecordEntriesDisplay();
  }

  private calculateRecordEntriesDisplay() {
    if (!this.pageSize || !this.pageSize) {
      return;
    }
    if (this.total == null || this.total == 0) {
      this.startRecordNum = 0;
      this.endRecordNum = 0;
      this.maxPage = 1;
    } else if (this.total <= this.pageSize) {
      this.startRecordNum = 1;
      this.endRecordNum = this.total;
      this.maxPage = 1;
    } else {
      this.maxPage = Math.ceil(this.total / this.pageSize);
      this.startRecordNum = this.pageSize * (this.page - 1) + 1;
      this.endRecordNum = this.page === this.maxPage ? this.total : this.pageSize * this.page;
    }
  }

  $onChanges(changes) {
    this.resetPage();
    this.calculateRecordEntriesDisplay();
  }

  $onDestroy() {}

  private resetPage() {
    this.page = 1;
  }

  public updateParentPageSize() {
    this.resetPage();
    this.calculateRecordEntriesDisplay();
  }

  public nextPage() {
    this.page += 1;
    this.calculateRecordEntriesDisplay();
  }

  public prevPage() {
    if (this.page > 1) this.page -= 1;
    this.calculateRecordEntriesDisplay();
  }
}

angular.module('fuse').component('swanDataTablePaging', new DataTablePagingComponent());
