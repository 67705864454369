import { breezeClass } from '@common/models/baseClass';
import { AssetAttachment } from './AssetAttachment';
import { AssetClass } from './AssetClass';
import { AuthAccount } from './AuthAccount';
import { AuthUser } from './AuthUser';
import { DataCollectionProfile } from './DataCollectionProfile';
import { Fertiliser } from './Fertiliser';
import { IrrigationController } from './IrrigationController';
import { ObsHealthIndex } from './ObsHealthIndex';
import { ObsNutrients } from './ObsNutrients';
import { ObsSoilMoisture } from './ObsSoilMoisture';
import { ObsWaterFlow } from './ObsWaterFlow';
import { ObsWaterStore } from './ObsWaterStore';
import { ObsWeatherRollup } from './ObsWeatherRollup';
import { PropertyValue } from './PropertyValue';
import { Sensor } from './Sensor';
import { Site } from './Site';
import { SiteAsset } from './SiteAsset';
import { SysMessageLog } from './SysMessageLog';
import { ValueRange } from './ValueRange';
import { WaterStore } from './WaterStore';
import { WorkAgent } from './WorkAgent';

export class Asset extends breezeClass implements breeze.Entity {
  /// <code> Place custom code between <code> tags
  Paths: any[];
  /// </code>

  // Generated code. Do not place code below this line.
  AssetId: number;
  OwnerAccountId: number;
  AssetClassId: number;
  Name: string;

  Status: string;
  EnableScheduleApi: boolean;
  Shared: boolean;
  Latitude: number;
  Longitude: number;
  Elevation: number;
  Orientation: number;
  Geometry: any;
  GeoJSON: string;
  TZStandardName: string;
  ReportingUnits: string;
  EffectiveFrom: Date;
  EffectiveTo: Date;
  Comment: string;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  AssetAttachments: AssetAttachment[];
  AssetClass: AssetClass;
  AuthAccount: AuthAccount;
  AuthUser: AuthUser;
  ChildAssets: SiteAsset[];
  Fertiliser: Fertiliser;
  ObsHealthIndexs: ObsHealthIndex[];
  ObsNutrients: ObsNutrients[];
  ObsSoilMoistures: ObsSoilMoisture[];
  ObsWaterFlows: ObsWaterFlow[];
  ObsWaterStores: ObsWaterStore[];
  ObsWeatherRollups: ObsWeatherRollup[];
  ParentAssets: SiteAsset[];
  Sensor: Sensor;
  IrrigationController: IrrigationController;
  Site: Site;
  SysMessageLogs: SysMessageLog[];
  ValueRanges: ValueRange[];
  WaterStore: WaterStore;
  WorkAgent: WorkAgent;
  ControllerProfiles: DataCollectionProfile[];

  PropertyValues: PropertyValue[];
}
