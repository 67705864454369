import * as angular from 'angular';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { AmGraphDataValues, unitSizes } from '@common/enums';
import { LanguageService } from '@services/language.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';

class WaterUsageSummaryChartComponent implements angular.IComponentOptions {
  bindings = {
    waterUsageSummary: '<',
    totalArea: '<',
    usageAsRate: '<',
  };
  controller = WaterUsageSummaryChartController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/waterusage-summary-chart.html';
}

class WaterUsageSummaryChartController {
  private _unitOfMeasureService: UnitOfMeasureService;
  private _languageService: LanguageService;
  private waterUsageSummaryChart: AmCharts.AmSerialChart;
  private waterUsageSummary: fuse.dashboardWaterUsageItem[];
  private volumeLargeUnit: uomUnit;
  private volumeAreaLargeUnit: uomUnit;
  private volumeHugeUnit: uomUnit;
  private volumeAreaHugeUnit: uomUnit;
  private usageAsRate: boolean;
  private totalArea: number;

  constructor(UnitOfMeasureService: UnitOfMeasureService, LanguageService: LanguageService) {
    this._unitOfMeasureService = UnitOfMeasureService;
    this._languageService = LanguageService;
    this.volumeLargeUnit = this._unitOfMeasureService.getUnits('Volume', unitSizes.large);
    this.volumeAreaLargeUnit = this._unitOfMeasureService.getUnits('Volume/Area', unitSizes.large);
    this.volumeHugeUnit = this._unitOfMeasureService.getUnits('Volume', unitSizes.huge);
    this.volumeAreaHugeUnit = this._unitOfMeasureService.getUnits('Volume/Area', unitSizes.huge);
  }

  $onChanges(changes) {
    if (changes.waterUsageSummary?.currentValue) {
      this.displayWaterUsageSummaryChart();
    }

    if (changes.usageAsRate) {
      this.displayWaterUsageSummaryChart();
    }
  }

  $onDestroy() {
    if (this.waterUsageSummaryChart) {
      this.waterUsageSummaryChart.legend = null;
      this.waterUsageSummaryChart.clear();
      this.waterUsageSummaryChart = null;
    }
  }

  private displayWaterUsageSummaryChart() {
    if (this.waterUsageSummary == null || this.usageAsRate == null || this.totalArea == null || this.totalArea == 0) {
      return;
    }

    let dataProvider: fuse.dashboardWaterUsageItem[] = [];

    if (this.usageAsRate == false) {
      dataProvider = this.waterUsageSummary.map((water) => {
        return {
          dateDisplay: DateUtils.Locale.asDateDayAndMonth(water.localeDate),
          waterActual: this._unitOfMeasureService.convertFromBase('Volume', unitSizes.large, water.waterActual),
          waterBudget: this._unitOfMeasureService.convertFromBase('Volume', unitSizes.large, water.waterBudget),
          waterRunningActual: this._unitOfMeasureService.convertFromBase('Volume', unitSizes.huge, water.waterRunningActual),
          waterRunningBudget: this._unitOfMeasureService.convertFromBase('Volume', unitSizes.huge, water.waterRunningBudget),
        } as unknown as fuse.dashboardWaterUsageItem;
      });
    } else {
      dataProvider = this.waterUsageSummary.map((water) => {
        return {
          dateDisplay: DateUtils.Locale.asDateDayAndMonth(water.localeDate),
          waterActual: this._unitOfMeasureService.convertFromBase('Volume/Area', unitSizes.large, water.waterActual / this.totalArea),
          waterBudget: this._unitOfMeasureService.convertFromBase('Volume/Area', unitSizes.large, water.waterBudget / this.totalArea),
          waterRunningActual: this._unitOfMeasureService.convertFromBase(
            'Volume/Area',
            unitSizes.huge,
            water.waterRunningActual / this.totalArea,
          ),
          waterRunningBudget: this._unitOfMeasureService.convertFromBase(
            'Volume/Area',
            unitSizes.huge,
            water.waterRunningBudget / this.totalArea,
          ),
        } as unknown as fuse.dashboardWaterUsageItem;
      });
    }
    const weeklyUnit = this.usageAsRate ? this.volumeAreaLargeUnit : this.volumeLargeUnit;
    const runningTotalUnit = this.usageAsRate ? this.volumeAreaHugeUnit : this.volumeHugeUnit;
    const axisRunning = {
      position: 'right',
      title: `${this._languageService.instant('COMMON.RUNNING_TOTALS')} (${runningTotalUnit})`,
      id: 'axisRunning',
      minimum: 0,
    } as AmCharts.ValueAxis;
    const axisPeriod = {
      position: 'left',
      title: `${this._languageService.instant('COMMON.WEEKLY_TOTALS')} (${weeklyUnit})`,
      id: 'axisPeriod',
      minimum: 0,
    } as AmCharts.ValueAxis;
    const budgetGraph = {
      id: 'budget',
      fillAlphas: 0.9,
      lineAlpha: 0.2,
      title: this._languageService.instant('COMMON.BUDGET'),
      type: 'column',
      fillColors: '#90CAF9',
      valueField: 'waterBudget',
      valueAxis: axisPeriod,
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `Budget: <b> ${val.toFixed(2)}</b> ${weeklyUnit}`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;
    const actualGraph = {
      id: 'actual',
      fillAlphas: 0.9,
      lineAlpha: 0.2,
      title: this._languageService.instant('COMMON.ACTUAL'),
      type: 'column',
      clustered: false,
      columnWidth: 0.5,
      fillColors: '#1A237E',
      valueField: 'waterActual',
      valueAxis: axisPeriod,
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.ACTUAL')}: <b> ${val.toFixed(2)}</b> ${weeklyUnit}`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;
    const budgetRunningGraph = {
      id: 'budgetrunning',
      bullet: 'round',
      bulletAlpha: 0,
      fillAlphas: 0.2,
      lineAlpha: 0.9,
      title: this._languageService.instant('COMMON.RUNNING_BUDGET'),
      type: 'line',
      lineColor: '#27aae0',
      lineThickness: 3,
      valueField: 'waterRunningBudget',
      valueAxis: axisRunning,
      useNegativeColorIfDown: true,
      negativeLineColor: '#ffffff00',
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.RUNNING_BUDGET')}: <b> ${val.toFixed(2)}</b> ${runningTotalUnit}`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;
    const actualRunningGraph = {
      id: 'actualrunning',
      bullet: 'round',
      bulletAlpha: 0,
      fillAlphas: 0.2,
      lineAlpha: 0.9,
      title: this._languageService.instant('COMMON.RUNNING_ACTUAL'),
      type: 'line',
      clustered: false,
      lineColor: '#1A237E',
      lineThickness: 3,
      valueField: 'waterRunningActual',
      valueAxis: axisRunning,
      useNegativeColorIfDown: true,
      negativeLineColor: '#ffffff00',
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.RUNNING_ACTUAL')}: <b> ${val.toFixed(2)}</b> ${runningTotalUnit}`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;
    const waterUsageChartOption = {
      theme: 'light',
      type: 'serial',
      dataProvider: dataProvider,
      legend: {
        useGraphSettings: true,
        position: 'top',
        valueWidth: 0,
      },
      valueAxes: [axisRunning, axisPeriod],
      startDuration: 0,
      graphs: [budgetGraph, actualGraph, budgetRunningGraph, actualRunningGraph] as AmCharts.AmGraph[],
      plotAreaFillAlphas: 0.1,
      categoryField: 'dateDisplay',
      categoryAxis: {
        gridPosition: 'start',
        gridAlpha: 0,
      },
    } as AmCharts.AmSerialChart;
    this.waterUsageSummaryChart = AmCharts.makeChart('water-usage-chart', waterUsageChartOption) as AmCharts.AmSerialChart;
    this.waterUsageSummaryChart.validateNow();
  }
}

angular.module('fuse').component('waterUsageSummaryChart', new WaterUsageSummaryChartComponent());
