import * as angular from 'angular';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { AmGraphDataValues } from '@common/enums';
import { CalculatedResults } from '@common/nutrients.interface';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { uomUnit } from '@services/unit-of-measure.service';
import { FertiliserService } from '@services/nutrients/fertiliser.service';
import { NutrientService } from '@services/nutrients/nutrient.service';
import { BaseController } from 'src/app/base.controller';
import { Asset } from 'src/app/_DBContext/Asset';
import { Fertiliser } from 'src/app/_DBContext/Fertiliser';
import { NutrientFields } from 'src/app/_DBContext/NutrientFields';
import { ObsNutrients } from 'src/app/_DBContext/ObsNutrients';

class SWANNitrogenFormComponent implements angular.IComponentOptions {
  bindings = {
    parentPage: '@',
    assetId: '<',
    defaultUnit: '<',
    fertiliserConcentrationMode: '<',
    fertiliserSpecificGravity: '<',
    calculatedResults: '=',
    rejectChanges: '<',
    obsNutrient: '=',
    reloadChanges: '<',
    uomUnit: '<',
    onNitrogenChanged: '&',
  };
  controller = NitrogenFormController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/nutrients/nitrogen-form.component.html';
}

class NitrogenFormController extends BaseController {
  public units;
  public selectedCompound1;
  public selectedCompound2;
  public selectedCompound4;
  public parentPage: string;
  public asset: Asset;
  public fert: Fertiliser;
  public assetId: number;
  public defaultUnit: string;
  public fertiliserConcentrationMode: string;
  public fertiliserSpecificGravity: number;
  public calculatedResults: CalculatedResults;
  public selectedUnitC;
  public showUnits: boolean;
  public nitrateNValue: number = 0;
  public no3Value: number = 0;
  public nh4Value: number = 0;
  public totalNValue: number = 0;
  public otherNValue: number = 0;
  public nitrateNValueWeight: number = 0;
  public no3ValueWeight: number = 0;
  public nh4ValueWeight: number = 0;
  public totalNValueWeight: number = 0;
  public otherNValueWeight: number = 0;

  public theChartData = [];
  public obsNutrient: ObsNutrients;
  public noot: NutrientFields;

  public nitrogenSigns: string[] = [];
  public uomUnit: uomUnit;

  private _fertiliserService: FertiliserService;
  private _languageService: LanguageService;
  private _nutrientService: NutrientService;

  private compounds;
  private onNitrogenChanged: Function;


  constructor(
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    FertiliserService: FertiliserService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    NutrientService: NutrientService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.NutrientsAnalytesFull);

    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;
    this._nutrientService = NutrientService;

    this.entityManager = DataEntityService.manager;
  }

  $onInit() {
    this.compounds = [
      { id: 0, value: this._languageService.instant('NUTR.CHEM.NO3') },
      { id: 1, value: this._languageService.instant('NUTR.CHEM.NH4') },
      { id: 2, value: this._languageService.instant('NUTR.CHEM.NITRATE_AS_N') },
      { id: 3, value: this._languageService.instant('NUTR.CHEM.OTHER_N') },
      { id: 4, value: this._languageService.instant('NUTR.CHEM.NO2') },
    ];

    this.showUnits = true;
    this.units = 'mg/L';

    this.selectedCompound1 = this.compounds[0];
    this.selectedCompound2 = this.compounds[1];
    this.selectedCompound4 = this.compounds[3];
    switch (this.parentPage) {
      case 'fertiliser':
        this.showUnits = false;
        break;
      default:
        this.showUnits = true;
    }
  }

  $onDestroy() {
    this.entityManager.clear();
  }

  $onChanges(changes) {
    //Temp fix for Cancel changes where models are not binding directly to control
    if ((changes.rejectChanges?.currentValue > 0 || changes.reloadChanges?.currentValue > 0) && angular.isDefined(this.obsNutrient)) {
      this.selectedCompound1 = this.compounds[0]; // force NO3 selection instead of NO2
      this.no3Value = this._fertiliserService.NO3N_to_NO3(this.obsNutrient.Noots.NO3_N);
      this.nh4Value = this._fertiliserService.NH4N_to_NH4(this.obsNutrient.Noots.NH4_N);

      this.nitrogenSigns['NO3'] = this._nutrientService.getNutrientSign(this.obsNutrient.Noots.NO3_N);
      this.no3Value *= this.obsNutrient.Noots.NO3_N < 0 && this.obsNutrient.Noots.NO3_N !== -9999 ? -1 : 1;

      this.nitrogenSigns['NH4'] = this._nutrientService.getNutrientSign(this.obsNutrient.Noots.NH4_N);
      this.nh4Value *= this.obsNutrient.Noots.NH4_N < 0 && this.obsNutrient.Noots.NH4_N !== -9999 ? -1 : 1;

      this.calculate(true);
    }
    if (
      angular.isDefined(this.obsNutrient) &&
      changes.fertiliserConcentrationMode &&
      !!changes.fertiliserConcentrationMode.currentValue
    ) {
      this.calculate(true);
    }
    if (
      angular.isDefined(this.obsNutrient) &&
      this.defaultUnit == 'L' &&
      changes.fertiliserSpecificGravity != null &&
      changes.fertiliserSpecificGravity.currentValue !== null
    ) {
      if (changes.fertiliserSpecificGravity.currentValue <= 0) {
        return;
      }
      this.calculate(true, true);
    }
  }

  public calculate(isEdit: boolean, isSpecificGravityChange: boolean = false) {
    const attrs = ['totalNValue', 'nitrateNValue', 'no3Value', 'otherNValue', 'nh4Value'];
    if (this.fertiliserSpecificGravity > 0) {
      switch (this.fertiliserConcentrationMode) {
        case 'w/w':
          // Calculate w/v from w/w input
          attrs.forEach((attr) => {
            this[attr] = this[attr + 'Weight'] * this.fertiliserSpecificGravity;
          });
          break;
        case 'w/v':
          // Calculate w/w from w/v input
          attrs.forEach((attr) => {
            this[attr + 'Weight'] = this[attr] / this.fertiliserSpecificGravity;
          });
          break;
      }
    }

    //if (!this.obsNutrient.Noots.NO3)
    //	this.obsNutrient.Noots.NO3 = 0;

    //if (!this.obsNutrient.Noots.NH4)
    //	this.obsNutrient.Noots.NH4 = 0;

    //there are two ways we can caluclate, first one If we know NO3 and NH4 value
    //second one NitrateN and TotalN value,
    //from db we get NO3 and NH4, that means edit mode we have NO3 and NH4
    //from UI we have NitrateN and Total N

    if (!isEdit || isSpecificGravityChange) {
      // update database values
      this.obsNutrient.Noots.NO3_N = this._nutrientService.calculateDBValue(this.nitrogenSigns['NitrateN'], this.nitrateNValue);
      this.obsNutrient.Noots.NH4_N = this._nutrientService.calculateDBValue(this.nitrogenSigns['OtherN'], this.otherNValue);
    } else {
      // update nutrient signs
      this.nitrogenSigns['NitrateN'] = this._nutrientService.getNutrientSign(this.obsNutrient.Noots.NO3_N);
      this.nitrogenSigns['OtherN'] = this._nutrientService.getNutrientSign(this.obsNutrient.Noots.NH4_N);
    }

    if (!isSpecificGravityChange) {
      // update display values NO3
      this.nitrateNValue = this._nutrientService.calculateDisplayValue(this.obsNutrient.Noots.NO3_N);
      const tempNO3Value = this._fertiliserService.NO3N_to_NO3(this.obsNutrient.Noots.NO3_N);
      this.no3Value = tempNO3Value < 0 ? 0 : tempNO3Value;

      // update display values NH4
      this.otherNValue = this._nutrientService.calculateDisplayValue(this.obsNutrient.Noots.NH4_N);
      const tempNH4Value = this._fertiliserService.NH4N_to_NH4(this.obsNutrient.Noots.NH4_N);
      this.nh4Value = tempNH4Value < 0 ? 0 : tempNH4Value;

      // update display values TotalN
      this.totalNValue =
        (this.obsNutrient.Noots.NO3_N < 0 ? 0 : this.nitrateNValue) + (this.obsNutrient.Noots.NH4_N < 0 ? 0 : this.otherNValue);
    }

    // update chart
    this.updateChart();

    if (this.fertiliserSpecificGravity > 0) {
      // Calculate w/w values from w/v input or database
      attrs.forEach((attr) => {
        this[attr + 'Weight'] = this[attr] / this.fertiliserSpecificGravity;
      });
    }
    this.onNitrogenChanged();
  }

  public doSamplingCalculation(isEdit: boolean) {
    if (!this.obsNutrient.Noots.NO3_N) this.obsNutrient.Noots.NO3_N = 0;

    const NO3 = this.nitrogenSigns['NO3'] !== 'blank' || this.nitrogenSigns['NO2'] !== 'blank' ? 0 : this.no3Value;

    if (!this.obsNutrient.Noots.NH4_N) this.obsNutrient.Noots.NH4_N = 0;

    const NH4 = this.nitrogenSigns['NH4'] !== 'blank' ? 0 : this.nh4Value;

    switch (this.selectedCompound1.value) {
      case 'NO2':
        this.nitrateNValue = 0;
        // otherN = NO2 (stored in NO3 but with lesser ratio) + NH4
        this.otherNValue = NO3 + NH4;
        break;

      case 'NO3':
        this.nitrateNValue = NO3;
        // otherN = no NO2 here and just NH4
        this.otherNValue = NH4;
        break;

      default:
        break;
    }

    this.totalNValue =
      (this.obsNutrient.Noots.NO3_N < 0 ? 0 : this.nitrateNValue) + (this.obsNutrient.Noots.NH4_N < 0 ? 0 : this.otherNValue);

    this.updateChart();
  }

  private updateChart() {
    this.theChartData = [
      {
        name: this._languageService.instant('NUTR.CHEM.TOTAL_N'),
        value: this.totalNValue,
      },
      {
        name: 'NO3',
        value: this.no3Value,
      },
      {
        name: 'NH4',
        value: this.nh4Value,
      },
      {
        name: this._languageService.instant('NUTR.CHEM.NITRATE_AS_N'),
        value: this.obsNutrient.Noots.NO3_N < 0 ? 0 : this.nitrateNValue,
      },
      {
        name: this._languageService.instant('NUTR.CHEM.OTHER_N'),
        value: this.obsNutrient.Noots.NH4_N < 0 ? 0 : this.otherNValue,
      },
    ];

    AmCharts.makeChart('nitrogen-summary-chart', {
      type: 'serial',
      theme: 'light',
      dataProvider: this.theChartData,
      backgroundColor: '#EFEFEF',
      backgroundAlpha: 0.8,
      valueAxes: [
        {
          gridColor: '#FFFFFF',
          gridAlpha: 0,
          labelsEnabled: false,
          axisAlpha: 0,
        },
      ],
      gridAboveGraphs: true,
      startDuration: 0,
      graphs: [
        {
          fillAlphas: 0.8,
          lineAlpha: 0.2,
          fillColors: '#67b7dc',
          type: 'column',
          valueField: 'value',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            if (graphDataItem.values) {
              const val = (graphDataItem.values as AmGraphDataValues).value;
              const txt = graphDataItem.category;
              if (val) {
                return txt + ': ' + val.toFixed(2);
              }
            }
            return '';
          },
        },
      ],
      chartCursor: {
        categoryBalloonEnabled: false,
        cursorAlpha: 0,
        zoomable: false,
      },
      categoryField: 'name',
      categoryAxis: {
        gridPosition: 'start',
        gridAlpha: 0,
      },
    } as AmCharts.AmSerialChart);
  }
}

angular.module('app.nutrients').component('swanNitrogenForm', new SWANNitrogenFormComponent());
