import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { WorkAgent } from './WorkAgent';

export class WaterStore extends breezeClass implements breeze.Entity {
  AssetId: number;
  Type: string;
  Name: string;
  CapacityKL: number;
  LicenceReference: string;
  LicenceValidFrom: Date;
  LicenceValidTo: Date;
  Notes: string;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Asset: Asset;
  AuthUser: AuthUser;
  WorkAgent: WorkAgent;
}
