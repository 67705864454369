import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

class SelectAccountSitesComponent implements angular.IComponentOptions {
  bindings = {
    accountSites: '<',
    accountGroups: '<',
    onChange: '&',
  };
  controller = SelectAccountSitesController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/select-account-sites.html';
}

class SelectAccountSitesController extends BaseController {
  private onChange: Function;
  private selectedSites = [] as fuse.siteProfileDto[];
  private unselectedSites = [] as fuse.siteProfileDto[];
  public selectedGroups: number[];
  private accountGroups: fuse.groupProfileDto[];
  public unselectedFilteredSites = [] as fuse.siteProfileDto[];
  public selectedFilteredSites = [] as fuse.siteProfileDto[];
  public unselectedFilter = '';
  public selectedFilter = '';
  public isAllUnselectedFilteredSitesChecked = false;
  public isAllSelectedSitesChecked = false;

  constructor(
    $scope: angular.IScope,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );
  }

  $onChanges(changes) {
    if (changes.accountSites?.currentValue.length) {
      this.unselectedSites = changes.accountSites.currentValue;
      this.unselectedFilteredSites = this.unselectedSites.filter((a) =>
        a.name.toLowerCase().includes(this.unselectedFilter.toLowerCase()),
      );
      this.selectedSites = [];
      this.selectedFilteredSites = [];
    }
  }

  private selectedSitesChanged() {
    this.onChange({ selectedSites: this.selectedFilteredSites });
  }

  public pushSelectedSites(): void {
    const movedSites = this.unselectedFilteredSites.filter((a) => a.isChecked);

    if (movedSites.length) {
      this.unselectedSites = this.unselectedSites.filter((a) => movedSites.some((b) => b.name == a.name) == false);
      this.unselectedFilteredSites = this.unselectedSites.filter((a) =>
        a.name.toLowerCase().includes(this.unselectedFilter.toLowerCase()),
      );
      movedSites.forEach((a) => (a.isChecked = false));
      this.selectedSites.push(...movedSites);
      this.selectedFilteredSites = this.selectedSites.filter((a) => a.name.toLowerCase().includes(this.selectedFilter.toLowerCase()));
      this.itemChanged();
      this.selectedSitesChanged();
    }
  }

  public popSelectedSites(): void {
    const movedSites = this.selectedFilteredSites.filter((a) => a.isChecked);
    if (movedSites.length) {
      this.selectedSites = this.selectedSites.filter((a) => a.isChecked == false);
      this.selectedFilteredSites = this.selectedSites.filter((a) => a.name.toLowerCase().includes(this.selectedFilter.toLowerCase()));
      movedSites.forEach((a) => (a.isChecked = false));
      this.unselectedSites.push(...movedSites);
      this.unselectedFilteredSites = this.unselectedSites.filter((a) =>
        a.name.toLowerCase().includes(this.unselectedFilter.toLowerCase()),
      );
      this.itemChanged();
      this.selectedSitesChanged();
    }
  }

  public selectAllUnselected(): void {
    if (this.isAllUnselectedFilteredSitesChecked) {
      this.unselectedFilteredSites.forEach((a) => (a.isChecked = true));
    } else {
      this.unselectedFilteredSites.forEach((a) => (a.isChecked = false));
    }
    this.itemChanged();
  }

  public selectAllSelected(): void {
    if (this.isAllSelectedSitesChecked) {
      this.selectedSites.forEach((a) => (a.isChecked = true));
    } else {
      this.selectedSites.forEach((a) => (a.isChecked = false));
    }
  }

  public itemChanged(): void {
    if (this.selectedSites.length) {
      this.isAllSelectedSitesChecked = this.selectedSites.every((a) => a.isChecked);
    } else {
      this.isAllSelectedSitesChecked = false;
    }
    if (this.unselectedFilteredSites.length) {
      this.isAllUnselectedFilteredSitesChecked = this.unselectedFilteredSites.every((a) => a.isChecked);
    } else {
      this.isAllUnselectedFilteredSitesChecked = false;
    }
  }

  public filterUnselectedSites() {
    this.unselectedFilteredSites = this.unselectedSites.filter((a) =>
      a.name.toLowerCase().includes(this.unselectedFilter.toLowerCase()),
    );
    if (this.selectedGroups?.length) {
      const groups = this.accountGroups.filter((a) => this.selectedGroups.some((b) => b == a.groupId));
      const siteIds = [] as number[];
      groups.forEach((group) => {
        siteIds.push(...group.sites);
      });
      this.unselectedFilteredSites = this.unselectedFilteredSites.filter((a) => siteIds.some((b) => b == a.siteId));
    }
    this.itemChanged();
  }

  public filterSelectedSites() {
    this.selectedFilteredSites = this.selectedSites.filter((a) => a.name.toLowerCase().includes(this.selectedFilter.toLowerCase()));
    this.itemChanged();
    this.selectedSitesChanged();
  }
}

angular.module('fuse').component('selectAccountSites', new SelectAccountSitesComponent());
