import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { WorkAgent } from './WorkAgent';

export class Sensor extends breezeClass implements breeze.Entity {
  AssetId: number;
  Schedule: string;
  NextRun: Date;
  Height: number;
  Manufacturer: string;
  Model: string;
  AssetConfig: string;
  DataCollectionProfileId?: number;
  MeterInitialReading?: number;
  MeterIncrement?: number;
  UnitId?: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Asset: Asset;
  AuthUser: AuthUser;
  WorkAgent: WorkAgent;
}
