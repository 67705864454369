// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#select-account-sites md-checkbox {
  margin-bottom: 0;
}
#select-account-sites md-select {
  width: 120px;
}
#select-account-sites md-input-container {
  margin: 0 0 8px;
  padding: 0;
}
#select-account-sites md-input-container .md-errors-spacer {
  min-height: 0px;
}
#select-account-sites md-input-container .md-input {
  color: white;
  border-color: white;
}
#select-account-sites .select-sites-table {
  overflow: auto;
  height: calc(100vh - 340px);
  max-height: 400px;
}
#select-account-sites .select-sites-table table {
  border-spacing: 0;
  width: 100%;
}
#select-account-sites .select-sites-table table thead tr {
  height: 52px;
}
#select-account-sites .select-sites-table table thead tr th {
  background-color: #666;
  color: #fff;
  padding-top: 2px;
  padding-bottom: 2px;
  position: sticky;
  top: 0;
  z-index: 10;
}
#select-account-sites .select-sites-table table tbody {
  max-height: 300px;
}
#select-account-sites .select-sites-table table tbody tr td {
  padding-top: 2px;
  padding-bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/_components/select-account-sites.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR;AAEI;EACI,YAAA;AAAR;AAEI;EACI,eAAA;EACA,UAAA;AAAR;AACQ;EACI,eAAA;AACZ;AACQ;EACI,YAAA;EACA,mBAAA;AACZ;AAEI;EACI,cAAA;EACA,2BAAA;EACA,iBAAA;AAAR;AACQ;EACI,iBAAA;EACA,WAAA;AACZ;AACgB;EACI,YAAA;AACpB;AAAoB;EACI,sBAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,MAAA;EACA,WAAA;AAExB;AAEY;EACI,iBAAA;AAAhB;AAEoB;EACI,gBAAA;EACA,mBAAA;AAAxB","sourcesContent":["#select-account-sites {\r\n    md-checkbox {\r\n        margin-bottom: 0;\r\n    }\r\n    md-select {\r\n        width: 120px;\r\n    }\r\n    md-input-container {\r\n        margin: 0 0 8px;\r\n        padding: 0;\r\n        .md-errors-spacer {\r\n            min-height: 0px;\r\n        }\r\n        .md-input {\r\n            color: white;\r\n            border-color: white;\r\n        }\r\n    }\r\n    .select-sites-table {\r\n        overflow: auto;\r\n        height: calc(100vh - 340px);\r\n        max-height: 400px;\r\n        table {\r\n            border-spacing: 0;\r\n            width: 100%;\r\n            thead {\r\n                tr {\r\n                    height: 52px;\r\n                    th {\r\n                        background-color: #666;\r\n                        color: #fff;\r\n                        padding-top: 2px;\r\n                        padding-bottom: 2px;\r\n                        position: sticky;\r\n                        top: 0;\r\n                        z-index: 10;\r\n                    }\r\n                }\r\n            }\r\n            tbody {\r\n                max-height: 300px;\r\n                tr {\r\n                    td {\r\n                        padding-top: 2px;\r\n                        padding-bottom: 2px;\r\n                    }\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
