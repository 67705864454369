import * as angular from 'angular';
import { CropService } from '@services/crop.service';

class CropMetricIcon implements angular.IComponentOptions {
  bindings = {
    status: '<',
  };
  controller = CropMetricIconController;
  controllerAs = 'vm';
  template = `
    <i class="{{vm.opts[vm.status].icon}}">
      <md-tooltip ng-if="vm.status != null" md-direction="top">
        {{'CROPS.TARGET_STATUS.' + vm.status | i18next}}
      </md-tooltip>
    </i>
  `;
}

class CropMetricIconController {
  public opts = {};

  constructor(CropService: CropService) {
    this.opts = CropService.targetDisplay;
  }
}

angular.module('fuse').component('cropMetricIcon', new CropMetricIcon());
