import * as angular from 'angular';

angular.module('fuse').directive('draggable', () => {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      (element as any).draggable({
        stop: (event, ui) => {
          event.stopPropagation();
        },
      });
    },
  };
});
