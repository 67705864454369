import { breezeClass } from '@common/models/baseClass';

export class IrrigationPlanOverride extends breezeClass implements breeze.Entity {
  Id: number;
  AssetId: number;
  DayNumber: number;
  EndDayNumber: number;
  IrrigationPolicy: string;
  ObserveScheduleDays: boolean;
  MaximumVolume?: number;
  MinimumVolume: number;
  Comments: string;
}
