import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { Budget } from './Budget';
import { WorkAgent } from './WorkAgent';

export class SiteWeeklyFertiliser extends breezeClass implements breeze.Entity {
  Id: number;
  FertiliserBudget: number;
  FertiliserPlanned: number;
  FertiliserActual: number;
  FertiliserStatus: number;
  FertiliserId: number;
  BudgetId: number;
  Applications: string;
  AssetId: number;
  dayNumber: number;
  dayDisplayMMdd: string;
  dayDisplayYMD: string;
  dayOfWeek: string;
  QualityFactor: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  AuthUser: AuthUser;
  Budget: Budget;
  Fertiliser: Asset;
  WorkAgent: WorkAgent;
  Asset: Asset;
}
