import { breezeClass } from '@common/models/baseClass';
import { AuthUser } from './AuthUser';
import { SiteAsset } from './SiteAsset';
import { WorkAgent } from './WorkAgent';

export class DataInput extends breezeClass implements breeze.Entity {
  DataInputId: number;
  Name: string;
  DataType: string;
  StorageClass: string;
  DataStore: string;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  Sequence?: number;
  MinimumValue?: number;
  MaximumValue?: number;
  DataCategory: string;
  WorkAgentId: number;
  AuthUser: AuthUser;
  SiteAssets: SiteAsset[];
  WorkAgent: WorkAgent;
}
