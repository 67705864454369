import { breezeClass } from '@common/models/baseClass';
import { AuthUser } from './AuthUser';
import { WorkAgent } from './WorkAgent';

export class SoilTexture extends breezeClass implements breeze.Entity {
  Id: number;
  Name: string;
  DrainageCoefficient: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  AuthUser: AuthUser;
  WorkAgent: WorkAgent;
}
