import { breezeClass } from '@common/models/baseClass';
import { AuthUser } from './AuthUser';
import { WorkAgent } from './WorkAgent';

export class WorkAgentCollector extends breezeClass implements breeze.Entity {
  Id: number;
  DisplayName: string;
  ProviderId: string;
  ByGeolocation: boolean;
  ProviderConfig: string;
  AssetConfig: string;
  DataInputDataType: string;
  Template: string;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  AuthUser: AuthUser;
  WorkAgent: WorkAgent;
  QueueName: string;
  AuthAccountClassId: number;
  Visible: boolean;
}
