import * as angular from 'angular';
import { unitSizes, UnitTypes } from '@common/enums';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';

class WeatherSummaryChartComponent implements angular.IComponentOptions {
  bindings = {
    weatherSummary: '<',
  };
  controller = WeatherSummaryChartController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/weather-summary-chart.html';
}

class WeatherSummaryChartController extends BaseController {
  private _unitOfMeasureService: UnitOfMeasureService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private weatherChart: AmCharts.AmSerialChart;
  private dataProvider: fuse.dashboardWeatherItem[];
  private weatherSummary: fuse.dashboardWeatherItem[];
  public rainfallNormalUnit: string;
  public etoNormalUnit: string;
  public temperatureNormalUnit: string;
  public windspeedNormalUnit: string;
  public windspeedRangeLow: number;
  public windspeedRangeHigh: number;
  public adjustedTodayDayNumber: number;

  constructor(
    $scope: angular.IScope,
    UnitOfMeasureService: UnitOfMeasureService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._unitOfMeasureService = UnitOfMeasureService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;

    this.adjustedTodayDayNumber = DayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    this.rainfallNormalUnit = this._unitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
    this.etoNormalUnit = this._unitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
    this.temperatureNormalUnit = this._unitOfMeasureService.getUnitLabel('Temperature', unitSizes.normal);
    this.windspeedNormalUnit = this._unitOfMeasureService.getUnitLabel('Velocity', unitSizes.normal);
    this.windspeedRangeLow = this._unitOfMeasureService.convertFromBase('Velocity', unitSizes.normal, 5);
    this.windspeedRangeHigh = this._unitOfMeasureService.convertFromBase('Velocity', unitSizes.normal, 14);
  }

  $onChanges(changes) {
    if (changes.weatherSummary?.currentValue) {
      this.dataProvider = this.weatherSummary.map((weather) => {
        return {
          dayNumber: weather.dayNumber,
          localeDate: weather.localeDate,
          rainfall: this._unitOfMeasureService.convertFromBase('Fluid Depth', unitSizes.normal, weather.rainfall),
          eto: this._unitOfMeasureService.convertFromBase('Fluid Depth', unitSizes.normal, weather.eto),
          maxTemp: this._unitOfMeasureService.convertFromBase('Temperature', unitSizes.normal, weather.maxTemp),
          minTemp: this._unitOfMeasureService.convertFromBase('Temperature', unitSizes.normal, weather.minTemp),
          maxHumidity: weather.maxHumidity,
          minHumidity: weather.minHumidity,
          windSpeed: this._unitOfMeasureService.convertFromBase('Velocity', unitSizes.normal, weather.windSpeed),
        } as fuse.dashboardWeatherItem;
      });

      this.displayWeatherSummaryChart();
    }
  }

  $onDestroy() {
    if (this.weatherChart != null) {
      this.weatherChart.legend = null;
      this.weatherChart.clear();
      this.weatherChart = null;
    }
  }

  public labelFunc(prefix, unitType) {
    return (graphDataItem, graph) => {
      const val = (graphDataItem.values as any).value;
      if (!val) return '';
      let suff = unitType;
      let dp = 1;
      if (unitType != '%') {
        const unit = this._unitOfMeasureService.getUnits(unitType);
        suff = ' ' + unit.name;
        dp = unit.decimalPlaces;
      }
      return `${prefix}: ${val.toFixed(dp)}${suff}`;
    };
  }

  private displayWeatherSummaryChart() {
    const depthAxis = {
      id: 'depthAxis',
      dashLength: 1,
      position: 'right',
      title: `${this._languageService.instant('COMMON.WEATHER_READINGS.RAINFALL')} / ${this._languageService.instant(
        'COMMON.WEATHER_READINGS.ETO',
      )} (${this.rainfallNormalUnit})`,
      minimum: 0,
      gridThickness: 0,
    } as AmCharts.ValueAxis;
    const miscAxis = {
      id: 'miscAxis',
      dashLength: 1,
      title: this._languageService.instant('COMMON.OTHER'),
      position: 'left',
      minimum: 0,
      gridThickness: 0,
    } as AmCharts.ValueAxis;

    const weatherChartOption = {
      type: 'serial',
      theme: 'light',
      dataProvider: this.dataProvider,
      marginTop: 20,
      valueAxes: [depthAxis, miscAxis] as AmCharts.ValueAxis[],
      legend: {
        useGraphSettings: true,
        position: 'right',
        valueText: '',
      } as AmCharts.AmLegend,
      startDuration: null,
      graphs: [
        {
          id: 'rainfall',
          valueAxis: depthAxis,
          fillAlphas: 1,
          lineAlpha: 0.2,
          title: `${this._languageService.instant('COMMON.WEATHER_READINGS.RAINFALL')} (${this.rainfallNormalUnit})`,
          type: 'column',
          fillColors: '#03A9F4',
          valueField: 'rainfall',
          balloonFunction: this.labelFunc(this._languageService.instant('COMMON.WEATHER_READINGS.RAINFALL'), UnitTypes.FluidDepth),
        },
        {
          id: 'eto',
          valueAxis: depthAxis,
          bullet: 'round',
          bulletAlpha: 0,
          lineAlpha: 0.2,
          fillAlphas: 1,
          title: `${this._languageService.instant('COMMON.WEATHER_READINGS.ETO')} (${this.etoNormalUnit})`,
          type: 'column',
          lineColor: '#9C27B0',
          fillColors: '#9C27B0',
          lineThickness: 1,
          valueField: 'eto',
          balloonFunction: this.labelFunc(this._languageService.instant('COMMON.WEATHER_READINGS.ETO'), UnitTypes.FluidDepth),
        },
        {
          id: 'tempMax',
          valueAxis: miscAxis,
          bullet: 'round',
          bulletAlpha: 0,
          lineAlpha: 1,
          title: `${this._languageService.instant('COMMON.WEATHER_READINGS.TEMP_MAX')} (${this.temperatureNormalUnit})`,
          type: 'line',
          lineColor: '#FF9800',
          lineThickness: 3,
          valueField: 'maxTemp',
          balloonFunction: this.labelFunc(this._languageService.instant('COMMON.WEATHER_READINGS.TEMP_MAX'), UnitTypes.Temperature),
        },
        {
          id: 'tempMin',
          valueAxis: miscAxis,
          bullet: 'round',
          bulletAlpha: 0,
          lineAlpha: 1,
          title: `${this._languageService.instant('COMMON.WEATHER_READINGS.TEMP_MIN')} (${this.temperatureNormalUnit})`,
          type: 'line',
          lineColor: '#FFC107',
          lineThickness: 2,
          valueField: 'minTemp',
          balloonFunction: this.labelFunc(this._languageService.instant('COMMON.WEATHER_READINGS.TEMP_MIN'), UnitTypes.Temperature),
        },
        {
          id: 'humidityMax',
          valueAxis: miscAxis,
          bullet: 'round',
          bulletAlpha: 0,
          lineAlpha: 0.9,
          title: `${this._languageService.instant('COMMON.WEATHER_READINGS.HUMIDITY_MAX')} (%)`,
          type: 'line',
          lineColor: '#01579B',
          lineThickness: 3,
          valueField: 'maxHumidity',
          hidden: true,
          balloonFunction: this.labelFunc(this._languageService.instant('COMMON.WEATHER_READINGS.HUMIDITY_MAX'), '%'),
        },
        {
          id: 'humidityMin',
          valueAxis: miscAxis,
          bullet: 'round',
          bulletAlpha: 0,
          lineAlpha: 0.9,
          title: `${this._languageService.instant('COMMON.WEATHER_READINGS.HUMIDITY_MIN')} (%)`,
          type: 'line',
          lineColor: '#3F51B5',
          lineThickness: 2,
          valueField: 'minHumidity',
          hidden: true,
          balloonFunction: this.labelFunc(this._languageService.instant('COMMON.WEATHER_READINGS.HUMIDITY_MIN'), '%'),
        },
        {
          id: 'windspeed',
          valueAxis: miscAxis,
          bullet: 'round',
          bulletAlpha: 0,
          lineAlpha: 0.9,
          title: `${this._languageService.instant('COMMON.WEATHER_READINGS.WINDSPEED')} (${this.windspeedNormalUnit})`,
          type: 'line',
          lineColor: '#795548',
          lineThickness: 3,
          valueField: 'windSpeed',
          hidden: true,
          balloonFunction: this.labelFunc(this._languageService.instant('COMMON.WEATHER_READINGS.WINDSPEED'), UnitTypes.Velocity),
        },
      ] as AmCharts.AmGraph[],
      chartCursor: {
        categoryBalloonEnabled: true,
        cursorAlpha: 0,
        zoomable: false,
      } as AmCharts.ChartCursor,
      categoryField: 'localeDate',
      categoryAxis: {
        gridPosition: 'center',
        labelRotation: 0,
        position: 'bottom',
        // markPeriodChange: false,
        gridAlpha: 0,
        parseDates: true,
        dateFormats: [
          { period: 'DD', format: 'EEE DD' },
          { period: 'WW', format: 'EEE DD' },
          { period: 'MM', format: 'EEE DD' },
          { period: 'YYYY', format: 'EEE DD' },
        ],
      },
    } as AmCharts.AmSerialChart;
    this.weatherChart = AmCharts.makeChart('weather-chart', weatherChartOption) as AmCharts.AmSerialChart;
    this.addWeatherChartGuide(this.weatherChart);
  }

  private addWeatherChartGuide(chart: AmCharts.AmSerialChart) {
    if (!chart) return;
    const guideLine: AmCharts.Guide = new AmCharts.Guide();
    guideLine.date = this._dayNumberService.convertDayNumberToLocaleDate(this.adjustedTodayDayNumber);
    guideLine.above = true;
    guideLine.lineColor = 'red';
    guideLine.lineAlpha = 0.6;
    guideLine.inside = true;
    guideLine.dashLength = 2;

    if (chart.categoryAxis.addGuide !== undefined) {
      // remove any existing guides
      for (let idx = chart.categoryAxis.guides.length - 1; idx >= 0; idx--) {
        chart.categoryAxis.removeGuide(chart.categoryAxis.guides[idx]);
      }
      chart.categoryAxis.addGuide(guideLine);
    } else {
    }
    chart.validateNow();
  }
}

angular.module('fuse').component('weatherSummaryChart', new WeatherSummaryChartComponent());
