import { breezeClass } from '@common/models/baseClass';
import { AuthUser } from './AuthUser';
import { DataInput } from './DataInput';
import { SysAnalyteClient } from './SysAnalyteClient';
import { Unit } from './Unit';
import { UnitBaseClass } from './UnitBaseClass';
import { WorkAgent } from './WorkAgent';

export class SysAnalyte extends breezeClass implements breeze.Entity {
  Id: number;
  DataInputId: number;
  UnitBaseClassId: number;
  UnitClassId: number;
  Name: string;
  Symbol: string;
  Class: string;
  SubClass: string;
  IsIncludeInFertiliser: boolean;
  IsIncludeInAnalytes: boolean;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  AuthUser: AuthUser;
  DataInput: DataInput;
  SysAnalyteClients: SysAnalyteClient[];
  UnitBaseClass: UnitBaseClass;
  Unit: Unit;
  WorkAgent: WorkAgent;
}
