import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { Budget } from './Budget';
import { WorkAgent } from './WorkAgent';

export class SiteBudget extends breezeClass implements breeze.Entity {
  Id: number;
  BudgetId: number;
  AssetId: number;
  Month: string;
  Water_KL: number;
  Water_KL_FromBudget: number;
  NutrientBudget: string;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Asset: Asset;
  AuthUser: AuthUser;
  Budget: Budget;
  WorkAgent: WorkAgent;
}
