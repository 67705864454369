import * as moment from 'moment';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { WorkAgent } from './WorkAgent';
import { breezeClass } from '@common/models/baseClass';

export class ObsSoilMoisture extends breezeClass implements breeze.Entity {
  /// <code> Place custom code between <code> tags
  private _localDate: Date;

  get localDate(): Date {
    if (this._localDate == null && this.dayDisplayYMD != null) {
      this._localDate = moment(this.dayDisplayYMD, 'YYYY-MM-DD').toDate();
    }
    return this._localDate;
  }

  set localDate(date: Date) {
    this._localDate = date;
    this.dayDisplayYMD = moment(date).format('YYYY-MM-DD');
    this.dayNumber = -999;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  Id: number;
  Depth: number;
  Moisture: number;
  AssetId: number;
  dayNumber: number;
  dayDisplayMMdd: string;
  dayDisplayYMD: string;
  dayOfWeek: string;
  QualityFactor: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Asset: Asset;
  AuthUser: AuthUser;
  WorkAgent: WorkAgent;
}
