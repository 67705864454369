import { breezeClass } from '@common/models/baseClass';
import { SampleBlob } from '@common/nutrients.interface';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { WorkAgent } from './WorkAgent';

export class AssetAttachment extends breezeClass implements breeze.Entity {
  /// <code> Place custom code between <code> tags
  SampleBlob: SampleBlob;
  Type: string;
  /// </code>

  // Generated code. Do not place code below this line.
  Id: number;
  AssetId: number;
  Title: string;
  Format: string;
  Blob: any;
  Comment: string;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Asset: Asset;
  AuthUser: AuthUser;
  WorkAgent: WorkAgent;
  DayDisplayYMD: string;
}
