import { breezeClass } from '@common/models/baseClass';
import { AuthAccount } from './AuthAccount';
import { Crop } from './Crop';
import { CropCoefficient } from './CropCoefficient';
import { DataInput } from './DataInput';
import { ObsHealthIndex } from './ObsHealthIndex';
import { ObsSoilMoisture } from './ObsSoilMoisture';
import { ObsWaterFlow } from './ObsWaterFlow';
import { ObsWeatherRollup } from './ObsWeatherRollup';
import { SiteAsset } from './SiteAsset';
import { SysMessageLog } from './SysMessageLog';
import { WaterStore } from './WaterStore';
export class WorkAgent extends breezeClass implements breeze.Entity {
  WorkAgentId: number;
  Name: string;
  AuthAccounts: AuthAccount[];
  CropCoefficients: CropCoefficient[];
  Crops: Crop[];
  DataInputs: DataInput[];
  ObsHealthIndexes: ObsHealthIndex[];
  ObsSoilMoistures: ObsSoilMoisture[];
  ObsWaterFlows: ObsWaterFlow[];
  ObsWeatherRollups: ObsWeatherRollup[];
  SiteAssets: SiteAsset[];
  SysMessageLogs: SysMessageLog[];
  WaterStores: WaterStore[];
}
