import { uomUnit } from '@services/unit-of-measure.service';
import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { FertiliserPrice } from './FertiliserPrice';
import { WorkAgent } from './WorkAgent';

export class Fertiliser extends breezeClass implements breeze.Entity {
  /// <code> Place custom code between <code> tags
  LowestPrice: string;
  HighestPrice: string;
  QuantityLorKG: number;
  Unit: uomUnit;

  AssetId: number;
  Type: string;
  Formula: string;
  Manufacturer: string;
  /** kg = solid (Weight unit); anything else (L,Liter,kg/L) = liquid (Volume unit) */
  Units: string; //comment
  LiquidConcentrationMode: string;
  SpecificGravity: any;
  molHperUnit: string;
  Markets: string;
  Solubility: number;
  MixingStartDate: Date;
  MixingCost: number;
  TotVolMixInLiters: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Asset: Asset;
  AuthUser: AuthUser;
  FertiliserPrices: FertiliserPrice[];
  WorkAgent: WorkAgent;
}
