import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { NutrientDashboard } from '@common/models/interfaces';
import { AmGraphDataValues, unitSizes } from '@common/enums';

class NutrientsSummaryChartComponent implements angular.IComponentOptions {
  bindings = {
    nutrients: '<',
  };
  controller = NutrientsSummaryChartController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/nutrients-summary-chart.html';
}

interface INutrientElement {
  element: string;
  applied: number;
  planned: number;
}

class NutrientsSummaryChartController {
  private _unitOfMeasureService: UnitOfMeasureService;
  private _languageService: LanguageService;
  private nutrientsChart: AmCharts.AmSerialChart;
  private nutrients: NutrientDashboard;
  private dataProvider: INutrientElement[];
  private weightAreaNormalUnit: string;

  constructor(UnitOfMeasureService: UnitOfMeasureService, LanguageService: LanguageService) {
    this._unitOfMeasureService = UnitOfMeasureService;
    this._languageService = LanguageService;
    this.weightAreaNormalUnit = this._unitOfMeasureService.getUnitLabel('Weight/Area', unitSizes.normal);

    this.getBallonCallback = this.getBallonCallback.bind(this);
  }

  $onChanges(changes) {
    if (changes.nutrients?.currentValue) {
      this.dataProvider = this.nutrients.DisplayData.map((nutrient) => {
        return {
          element: nutrient.element,
          applied: this._unitOfMeasureService.convertFromBase('Weight/Area', unitSizes.normal, nutrient.applied),
          planned: this._unitOfMeasureService.convertFromBase('Weight/Area', unitSizes.normal, nutrient.planned),
        } as INutrientElement;
      });

      this.displayNutrientsSummaryChart();
    }
  }

  $onDestroy() {
    if (this.nutrientsChart != null) {
      this.nutrientsChart.legend = null;
      this.nutrientsChart.clear();
      this.nutrientsChart = null;
    }
  }

  private getBallonCallback(translateKey: string) {
    return (graphDataItem: AmCharts.GraphDataItem, amGraph: AmCharts.AmGraph) => {
      const value = (graphDataItem.values as AmGraphDataValues).value;

      if (value) {
        const title = this._languageService.instant(translateKey, { noot: graphDataItem.category, val: value });

        return `${title} <b>${this.weightAreaNormalUnit}</b>`;
      } else {
        return '';
      }
    };
  }

  private displayNutrientsSummaryChart() {
    // need trige the event after initialised
    const plannedGraph = {
      fillAlphas: 0.9,
      lineAlpha: 0.2,
      title: this._languageService.instant('COMMON.PLANNED'),
      type: 'column',
      valueField: 'planned',
      fillColors: '#67b7dc',
      balloonFunction: this.getBallonCallback('AC.SITE.PLANNED_TO_DATE'),
    } as AmCharts.AmGraph;
    const appliedGraph = {
      fillAlphas: 0.9,
      lineAlpha: 0.2,
      title: this._languageService.instant('COMMON.APPLIED'),
      type: 'column',
      clustered: false,
      columnWidth: 0.5,
      valueField: 'applied',
      balloonFunction: this.getBallonCallback('AC.SITE.APPLIED_TO_DATE'),
    } as AmCharts.AmGraph;
    const nutrientChartOption = {
      theme: 'light',
      type: 'serial',
      dataProvider: this.dataProvider,
      valueAxes: [
        {
          position: 'left',
          axisAlpha: 0,
          labelsEnabled: false,
          gridAlpha: 0,
        },
      ],
      legend: {
        useGraphSettings: true,
        position: 'right',
      },
      startDuration: 0,
      graphs: [plannedGraph, appliedGraph],
      plotAreaFillAlphas: 0.1,
      categoryField: 'element',
      categoryAxis: {
        gridPosition: 'start',
        gridAlpha: 0,
      } as AmCharts.CategoryAxis,
    } as AmCharts.AmSerialChart;

    this.nutrientsChart = AmCharts.makeChart('nutrients-group-chart', nutrientChartOption) as AmCharts.AmSerialChart;
    this.nutrientsChart.validateData();
  }
}

angular.module('fuse').component('nutrientsSummaryChart', new NutrientsSummaryChartComponent());
