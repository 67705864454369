import * as angular from 'angular';
import * as moment from 'moment';
import { Asset } from 'src/app/_DBContext/Asset';
import { SiteAsset } from 'src/app/_DBContext/SiteAsset';
import { FetchDataService } from '@services/fetch-data.service';
import { LanguageService } from '@services/language.service';
import { DayNumberService } from '@services/day-number.service';
import { PermissionService } from '@services/permission.service';
import { AccountsService } from '@services/administration/accounts.service';
import { BaseController } from 'src/app/base.controller';

class SitePickerComponent implements angular.IComponentOptions {
  bindings = {
    paramRequired: '@required',
    paramSiteStartDate: '@siteStartDate',
    paramInvalidSites: '<invalidSites',
  };
  controller = SitePickerController;
  controllerAs: string = 'vm';
  require = {
    model: 'ngModel',
  };
  templateUrl = 'src/app/_components/common/site-picker.component.html';
}

class SitePickerController extends BaseController {
  public paramRequired: string;
  public paramSiteStartDate: string;
  public paramInvalidSites: string;

  public model: angular.INgModelController;
  public sitePickerForm: angular.IFormController;

  public required: boolean;
  public showSiteStartDate: boolean;
  public invalidSites: number[];

  private sites: fuse.SiteStartDateDto[];
  public groups: Asset[] = [];

  private unselectedChosen: number[] = [];
  private selectedChosen: number[] = [];

  private selectedSites: number[] = [];
  private selectedGroup: Asset;

  private selectAllUnselectedState: boolean = false;
  private selectAllSelectedState: boolean = false;

  private siteFilter: number[] = [];

  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _accountsService: AccountsService;
  private _fetchDataService: FetchDataService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  constructor(
    $q: angular.IQService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    AccountsService: AccountsService,
    DayNumberService: DayNumberService,
    FetchDataService: FetchDataService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._q = $q;
    this._accountsService = AccountsService;
    this._fetchDataService = FetchDataService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
  }

  $onInit() {
    this.initialise();
  }

  private initialise() {
    this.fetchData();
  }

  public onSiteSelected() {
    this.model.$setViewValue(this.selectedSites);
    this.setValidity();
  }

  private setValidity() {
    if (this.model.$dirty) {
      this.model.$setValidity('required', !!this.selectedSites.length);
    }
  }

  public applyGroupFilter(): void {
    this.unselectedChosen = [];
    this.selectAllUnselectedState = false;
    if (this.selectedGroup == null) {
      //Remove Filter
      this.siteFilter = this.sites.map((s) => s.siteId).filter((s) => this.invalidSites.indexOf(s) < 0);
      return;
    }

    //Filter to sites belonging to selected group
    this._fetchDataService
      .fetchCMUSiteAssets(this.accountId, [this.selectedGroup.AssetId]) //Groups
      .then(
        (data) => {
          this.siteFilter = (data as SiteAsset[])
            .map((s) => {
              return s.ChildAssetId;
            })
            .filter((s) => this.invalidSites.indexOf(s) < 0);
        },
        (error) => {
          console.log('Unable to load group site list: ' + JSON.stringify(error));
          this._languageService.whoops();
          this._mdDialog.hide();
        },
      );
  }

  private fetchData() {
    const promises: angular.IPromise<any>[] = [];
    promises.push(
      this._accountsService
        .getActiveSitesStartDate(this.accountId) //Sites
        .then(
          (data) => {
            this.sites = data as fuse.SiteStartDateDto[];
            this.sites.forEach((s) => {
              (s as any).startDate =
                s.startDayNumber != null
                  ? moment(this._dayNumberService.convertDayNumberToDate(s.startDayNumber)).format('DD/MM/YYYY')
                  : this._languageService.instant('COMMON.NONE');
            });
          },
          (error) => {
            console.log('Unable to load active sites list: ' + JSON.stringify(error));
            this._languageService.whoops();
            this._mdDialog.hide();
          },
        ),
    );
    promises.push(
      this._fetchDataService
        .fetchAccountCMUs(this.accountId) //Groups
        .then(
          (data) => {
            this.groups = (data as Asset[]).filter((s) => s.Status != 'Archived');
          },
          (error) => {
            console.log('Unable to load active groups list: ' + JSON.stringify(error));
            this._languageService.whoops();
            this._mdDialog.hide();
          },
        ),
    );

    this._q.all(promises).then(() => {
      this.siteFilter = this.sites.map((s) => s.siteId).filter((s) => this.invalidSites.indexOf(s) < 0);
    });
  }

  public unselectedSiteList(): fuse.SiteStartDateDto[] {
    if (this.sites == null) return [];

    return this.sites.filter((s) => this.selectedSites.indexOf(s.siteId) < 0 && this.siteFilter.indexOf(s.siteId) >= 0);
  }

  public selectedSiteList(): fuse.SiteStartDateDto[] {
    if (this.sites == null) return [];

    return this.sites.filter((s) => this.selectedSites.indexOf(s.siteId) >= 0);
  }

  public pushSelectedSites(): void {
    this.selectedSites = this.selectedSites.concat(this.unselectedChosen);
    this.unselectedChosen = [];
    this.selectAllSelectedState = false;
    this.selectAllUnselectedState = false;
    this.onSiteSelected();
  }

  public popSelectedSites(): void {
    this.selectedSites = this.selectedSites.filter((s) => this.selectedChosen.indexOf(s) < 0);
    this.selectedChosen = [];
    this.selectAllSelectedState = false;
    this.selectAllUnselectedState = false;
    this.onSiteSelected();
  }

  public selectAllUnselected(): void {
    if (this.selectAllUnselectedState) {
      this.unselectedChosen = this.unselectedSiteList().map((s) => s.siteId);
    } else {
      this.unselectedChosen = [];
    }
  }

  public selectAllSelected(): void {
    if (this.selectAllSelectedState) {
      this.selectedChosen = this.selectedSiteList().map((s) => s.siteId);
    } else {
      this.selectedChosen = [];
    }
  }

  $onChanges(changes) {
    if (changes.paramInvalidSites) {
      this.invalidSites = [];
      switch (typeof changes.paramInvalidSites.currentValue) {
        case 'string': {
          const ids = this.paramInvalidSites.split(',');
          ids.forEach((id) => {
            id = id.trim();
            if (!Number.isNaN(id as any)) {
              this.invalidSites.push(Number(id));
            }
          });
          break;
        }
        case 'object': {
          if (changes.paramInvalidSites.currentValue) {
            this.invalidSites = changes.paramInvalidSites.currentValue as number[];
          }
          break;
        }
      }
      if (this.sites != null) {
        this.siteFilter = this.sites.map((s) => s.siteId).filter((s) => this.invalidSites.indexOf(s) < 0);
      }
    }
    if (changes.paramSiteStartDate) {
      switch (typeof changes.paramSiteStartDate.currentValue) {
        case 'string': {
          this.showSiteStartDate = this.paramSiteStartDate.toLowerCase() === 'true' || this.paramSiteStartDate === '';
          break;
        }
        case 'undefined': {
          this.showSiteStartDate = false;
          break;
        }
        case 'boolean': {
          this.showSiteStartDate = changes.paramSiteStartDate.currentValue;
          break;
        }
      }
    }
    if (changes.paramRequired) {
      switch (typeof changes.paramRequired.currentValue) {
        case 'string': {
          this.required = this.paramRequired.toLowerCase() === 'true' || this.paramRequired === '';
          break;
        }
        case 'undefined': {
          this.required = false;
          break;
        }
        case 'boolean': {
          this.required = changes.paramRequired.currentValue as boolean;
          break;
        }
      }
    }

    this.setValidity();
  }
}

angular.module('fuse').component('sitePicker', new SitePickerComponent());
