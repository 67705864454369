// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#weather-table table {
  width: 100%;
}
#weather-table table thead tr th:first-child {
  width: 100px;
  max-width: 120px;
}
#weather-table table tbody tr:nth-child(odd) {
  background-color: #f0f0f0;
}
#weather-table table tbody tr td:first-child {
  width: 100px;
  max-width: 120px;
  text-align: left;
  font-weight: bold;
  padding: 8px;
}
#weather-table table td:not(:first-child) {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/_components/weather-summary-table.scss"],"names":[],"mappings":"AACI;EACI,WAAA;AAAR;AACQ;EACI,YAAA;EACA,gBAAA;AACZ;AACQ;EACI,yBAAA;AACZ;AACQ;EACI,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;AACZ;AACQ;EACI,kBAAA;AACZ","sourcesContent":["#weather-table {\r\n    table {\r\n        width: 100%;\r\n        thead tr th:first-child {\r\n            width: 100px;\r\n            max-width: 120px;\r\n        }\r\n        tbody tr:nth-child(odd) {\r\n            background-color: #f0f0f0;\r\n        }\r\n        tbody tr td:first-child {\r\n            width: 100px;\r\n            max-width: 120px;\r\n            text-align: left;\r\n            font-weight: bold;\r\n            padding: 8px;\r\n        }\r\n        td:not(:first-child) {\r\n            text-align: center;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
