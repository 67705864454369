import * as angular from 'angular';
import * as $ from 'jquery';

angular.module('fuse').directive('latLong', () => {
  return {
    template: (elem, attr) => {
      const lcase = attr.type.toLowerCase();
      const ucase = attr.type.toUpperCase();

      const max = ucase == 'LONGITUDE' ? 180 : 90;
      const err = 'AC.EQUIP.ERROR_' + ucase;

      const input = document.createElement('input');

      $(input).attr({
        type: 'number',
        name: lcase,
        min: -max,
        max: max,
        'ng-model': attr.model,
        'ng-change': attr.validate,
        'ng-model-options': '{ debounce: 500 }',
      });

      if (attr.required == 'true') {
        $(input).prop('required', true);
      }

      if (attr.disable !== undefined) {
        $(input).attr('ng-disabled', attr.disable);
      }

      const messages = document.createElement('div');

      $(messages).attr({ 'ng-messages': `${attr.parent}.${lcase}.$error`, role: 'alert' });
      $(messages).append(`<div ng-message="required" ng-bind-html="${err} | i18next"></div>`);
      $(messages).append(`<div ng-message="min" ng-bind-html="${err} | i18next"></div>`);
      $(messages).append(`<div ng-message="max" ng-bind-html="${err} | i18next"></div>`);
      $(messages).append(`<div ng-message="google_location">{{'AC.EQUIP.ERROR_LOCATION' | i18next}}</div>`);

      return input.outerHTML + messages.outerHTML;
    },
  };
});
