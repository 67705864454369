import * as moment from 'moment';
import { Asset } from './Asset';
import { WorkAgent } from './WorkAgent';
import { AuthUser } from './AuthUser';
import { breezeClass } from '@common/models/baseClass';
export class SiteSettingsWater extends breezeClass implements breeze.Entity {
  /// <code> Place custom code between <code> tags
  private _localDate: Date;
  private _effectiveYMD: string;

  get localDate(): Date {
    if (this._localDate == null || this._effectiveYMD != this.EffectiveFromYMD) {
      if (this.EffectiveFromYMD != null) {
        this._localDate = moment(this.EffectiveFromYMD, 'YYYY-MM-DD').toDate();
        this._effectiveYMD = this.EffectiveFromYMD;
      }
    }
    return this._localDate;
  }

  set localDate(date: Date) {
    this._localDate = date;
    this.EffectiveFromYMD = moment(date).format('YYYY-MM-DD');
    this._effectiveYMD = this.EffectiveFromYMD;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  Id: number;
  DayNumber: number;
  CropWaterArea: number;
  CropWettedArea_perc: number;
  SystemEfficiencyCoefficient: number;
  IrrigationApplicationOneHour_mm: number;
  SprinklerLossConstantA: number;
  SprinklerLossConstantB: number;
  WaterBudgetId: number;
  WaterBudgetLowerTarget_percent: number;
  WaterBudgetUpperTarget_percent: number;
  WaterIntervalDays: number;
  WaterIntervalFromDayNumber: number;
  WaterMonday: boolean;
  WaterTuesday: boolean;
  WaterWednesday: boolean;
  WaterThursday: boolean;
  WaterFriday: boolean;
  WaterSaturday: boolean;
  WaterSunday: boolean;
  IrrigationDays: string;
  AssetId: number;
  Comment: string;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Asset: Asset;
  AuthUser: AuthUser;
  WorkAgent: WorkAgent;
  EffectiveFromYMD: string;
}
