import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { Contact } from './Contact';
import { CurrencyISO4217 } from './CurrencyISO4217';
import { WorkAgent } from './WorkAgent';

export class FertiliserPrice extends breezeClass implements breeze.Entity {
  Id: number;
  AssetId: number;
  AssetName: string;
  SupplierId: number;
  EffectiveFrom: Date;
  EffectiveTo: Date;
  PricePerUnit: number;
  UnitWeight: string;
  CurrencyCode: string;
  Comment: string;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Asset: Asset;
  AuthUser: AuthUser;
  Currency: CurrencyISO4217;
  WorkAgent: WorkAgent;
  Supplier: Contact;
}
