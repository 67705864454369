import { breezeClass } from '@common/models/baseClass';
import { AuthUser } from './AuthUser';
import { SysAnalyte } from './SysAnalyte';
import { WorkAgent } from './WorkAgent';

export class SysAnalyteClient extends breezeClass implements breeze.Entity {
  decimalPlaces: number;
  Id: number;
  AnalyteId: number;
  AccountId: number;
  SubClass: string;
  UnitClass: string;
  TargetRequirements: number;
  MaxThreshold: number;
  IsIncludeInFertiliser: boolean;
  IsIncludeInAnalytes: boolean;
  IsIncludeForDisplay: boolean;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  AuthUser: AuthUser;
  SysAnalyte: SysAnalyte;
  WorkAgent: WorkAgent;
}
