import * as angular from 'angular';

angular.module('fuse').directive('phoneRequiredPair', () => {
  return {
    link: (scope: angular.IScope, _element: JQLite, _attrs: angular.IAttributes, ngModel: any) => {
      const validate = () => {
        const phone1 = (scope as any).phoneRequiredPair?.phone1;
        const phone2 = (scope as any).phoneRequiredPair?.phone2;
        const isValid = !!phone1 || !!phone2; // At least one must be filled.

        ngModel.$setValidity('phoneRequiredPair', isValid);

        return isValid;
      };

      scope.$watch('phoneRequiredPair.phone1', validate);
      scope.$watch('phoneRequiredPair.phone2', validate);
    },
    restrict: 'A',
    require: 'ngModel',
    scope: {
      phoneRequiredPair: '=',
    },
  };
});
