import { breezeClass } from '@common/models/baseClass';
import { AuthUser } from './AuthUser';
import { UnitBaseClass } from './UnitBaseClass';
import { WorkAgent } from './WorkAgent';

export class Unit extends breezeClass implements breeze.Entity {
  Id: number;
  Name: string;
  Description: string;
  BaseClassId: number;
  MultiplierToBase: number;
  System: string;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  AuthUser: AuthUser;
  BaseClass: UnitBaseClass;
  WorkAgent: WorkAgent;
}
