import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { WorkAgent } from './WorkAgent';

export class ObsWaterStore extends breezeClass implements breeze.Entity {
  Id: number;
  Volume: number;
  Recipe: string;
  OtherTraceElements: string;
  AssetId: number;
  DateTime: Date;
  dayNumber: number;
  dayDisplayMMdd: string;
  dayDisplayYMD: string;
  dayOfWeek: string;
  QualityFactor: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Asset: Asset;
  AuthUser: AuthUser;
  WorkAgent: WorkAgent;
}
