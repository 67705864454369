import * as angular from 'angular';

class StatusIcon implements angular.IComponentOptions {
  bindings = {
    status: '<',
  };
  controller = StatusIconController;
  controllerAs = 'vm';
  template = `
    <i class="{{vm.opts[vm.status]}}">
      <md-tooltip md-direction="top">
        {{'COMMON.' + vm.status | i18next}}
      </md-tooltip>
    </i>
  `;
}

export class StatusIconController {
  opts: { Active: string; Suspended: string; Archived: string; };
  status: string;

  constructor() {
    this.opts = {
      Active: 'icon-checkbox-marked-circle green-500-fg',
      Suspended: 'icon-minus-circle orange-500-fg',
      Archived: 'icon-cancel red-500-fg',
    };
  }
}

angular.module('fuse').component('statusIcon', new StatusIcon());
