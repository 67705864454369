import * as angular from 'angular';
import { PropertyValue } from 'src/app/_DBContext/PropertyValue';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';
import { SWANConstants } from '@common/SWANConstants';

/** Dynamic input types based on definitions in the 'metadata.Type' and 'metadata.Property' database tables.
 *  NOTE: must be used inside a form with name "sensorProperties" for error messages to bind correctly.
 */
class SensorPropertyComponent {
  bindings = {
    propVal: '=',
    sensorProperty: '=',
    index: '@',
    parentCollection: '=',
  };
  controller = SensorPropertyController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/common/sensor-property.html';
}

class SensorPropertyController extends BaseController {
  public regexDecimalOrWholeNumber: RegExp;
  public propVal: PropertyValue;
  public property: fuse.property;
  public index: number;
  public formatMsg: string;

  public parentCollection;

  public thisElement = null;
  public tag = 'prop';
  public disable: boolean;

  private _isSuperUser: boolean;

  constructor(
    $scope: angular.IScope & { sensorProperties: any },
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this.regexDecimalOrWholeNumber = SWANConstants.RegexDecimalOrWholeNumber;
    this._isSuperUser = PermissionService.isSuperUser;
  }

  $onInit() {
    // Variables not available until onInit, can't init in the constructor.
    this.tag += this.index;
    this.property = this.propVal.fuseProp;
    this.disable = this.propVal.swanEditOnly && !this._isSuperUser;
  }

  public $postLink() {
    this.thisElement = this.scope['sensorProperties'][this.tag];
    // Form doesn't function unless it's part of same component as contents. Index/tag used to keep names unique, though not super-necessary.
    this.parentCollection.push(this);
  }

  /** forceShow = used on save to check all elements, and set touched to ensure all errors shown */
  public checkElement(forceShow = false): boolean {
    const errorMessage = this.propVal.validate();

    return this.setError(errorMessage, forceShow);
  }

  public setError(err, forceShow = false): boolean {
    const element = this.thisElement.input;

    if (!element) {
      return true;
    }

    if (err) {
      element.$error = { err: true };
      element.$invalid = true;

      if (isNaN(err)) {
        this.formatMsg = err;
      }

      if (forceShow) {
        element.$setTouched();
      }

      return false;
    } else {
      element.$error = {};
      element.$invalid = false;
      element.$validate(); // seems to be necessary to force dropdown lists to clear errors

      return true;
    }
  }
}

angular.module('fuse').component('sensorProperty', new SensorPropertyComponent());
