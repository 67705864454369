import { breezeClass } from '@common/models/baseClass';
import { AuthUser } from './AuthUser';
import { Crop } from './Crop';
import { WorkAgent } from './WorkAgent';

export class CropTolerance extends breezeClass implements breeze.Entity {
  Id: number;
  Name: string;
  Saturated: number;
  TooWet: number;
  Wet: number;
  Optimum: number;
  Dry: number;
  TooDry: number;
  LowTempThreshold: number;
  LowTemp: number;
  MidTempThreshold: number;
  MidTemp: number;
  HighTempThreshold: number;
  HighTemp: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  AuthUser: AuthUser;
  Crops: Crop[];
  WorkAgent: WorkAgent;
}
