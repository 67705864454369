import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { AmGraphDataValues, unitSizes } from '@common/enums';
import { DayNumberService } from '@services/day-number.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';

class SiteIrrigationSummaryChartComponent implements angular.IComponentOptions {
  bindings = {
    siteIrrigationSummary: '<',
  };
  controller = SiteIrrigationSummaryChartController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/site-irrigation-summary-chart.html';
}

class SiteIrrigationSummaryChartController extends BaseController {
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _permissionService: PermissionService;
  private _unitOfMeasureService: UnitOfMeasureService;

  private dataProvider: fuse.siteIrrigationInfoDto[];
  private siteIrrigationSummary: fuse.siteIrrigationInfoDto[];
  private siteIrrigationSummaryChart: AmCharts.AmSerialChart;
  public fluidDepthNormalUnit: string;

  constructor(
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.fluidDepthNormalUnit = this._unitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
  }

  $onChanges(changes) {
    if (changes.siteIrrigationSummary?.currentValue) {
      this.dataProvider = this.siteIrrigationSummary.map((item) => {
        return {
          dayNumber: item.dayNumber,
          localeDate: item.localeDate,
          soilMoistureLower: item.soilMoistureLower,
          soilMoistureUpper: item.soilMoistureUpper,
          soilSaturationPoint: item.soilSaturationPoint,
          rainfallObs: this._unitOfMeasureService.convertFromBase('Fluid Depth', unitSizes.normal, item.rainfallObs),
          irrigationDb: this._unitOfMeasureService.convertFromBase('Fluid Depth', unitSizes.normal, item.irrigationDb),
          soilMoisture: item.soilMoisture,
        } as fuse.siteIrrigationInfoDto;
      });

      this.displaySiteIrrigationSummaryChart();
    }
  }

  $onDestroy() {
    if (this.siteIrrigationSummaryChart != null) {
      this.siteIrrigationSummaryChart.legend = null;
      this.siteIrrigationSummaryChart.clear();
      this.siteIrrigationSummaryChart = null;
    }
  }

  private displaySiteIrrigationSummaryChart() {
    const mmAxis = {
      id: 'mmAxis',
      dashLength: 1,
      position: 'left',
      title: `${this._languageService.instant('COMMON.IRRIGATION_AND_RAIN')} (${this.fluidDepthNormalUnit})`,
      minimum: 0,
    } as AmCharts.ValueAxis;
    const smAxis = {
      id: 'smAxis',
      dashLength: 1,
      position: 'right',
      title: `${this._languageService.instant('COMMON.SOIL_MOISTURE')} (%)`,
      minimum: 0,
    } as AmCharts.ValueAxis;

    const soilMoistureLowRange = {
      //'id': 'soilMoistureLowRange',
      valueField: 'soilMoistureLower',
      title: this._languageService.instant('COMMON.BELOW_TARGET_RANGE'),
      valueAxis: smAxis,
      type: 'line',
      lineColor: '#FFC107',
      lineAlpha: 0.1,
      fillColors: ['#FFC107', '#ffffff'] as any,
      fillAlphas: 0.4,
      markerType: 'square',
      showBalloon: false,
      hidden: false,
    } as AmCharts.AmGraph;
    const soilMoistureOkRange = {
      //'id': 'soilMoistureOkRange',
      valueField: 'soilMoistureUpper',
      title: this._languageService.instant('COMMON.TARGET_RANGE'),
      valueAxis: smAxis,
      type: 'line',
      lineColor: '#3FA441',
      lineAlpha: 0.1,
      fillAlphas: 0.4,
      fillToGraph: soilMoistureLowRange,
      markerType: 'square',
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.LOWER_TARGET')}: ${val.toFixed(0)}%`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;
    const soilMoistureHighRange = {
      //id: 'soilMoistureHighRange',
      valueField: 'soilSaturationPoint',
      title: this._languageService.instant('COMMON.ABOVE_TARGET_RANGE'),
      valueAxis: smAxis,
      type: 'line',
      lineColor: '#0084CA',
      lineAlpha: 0.1,
      fillColors: ['#00ffff', '#0084CA'] as any,
      fillAlphas: 0.4,
      fillToGraph: soilMoistureOkRange,
      markerType: 'square',
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.UPPER_TARGET')}: ${val.toFixed(0)}%`;
        } else {
          return '';
        }
      },
      hidden: false,
    } as AmCharts.AmGraph;
    const rainFall = {
      //'id': 'Rainfall',
      valueField: 'rainfallObs',
      title: this._languageService.instant('COMMON.WEATHER_READINGS.RAINFALL'),
      valueAxis: mmAxis,
      type: 'column',
      clustered: false,
      columnWidth: 0.9,
      alphaField: 'alpha',
      fillAlphas: 0.8,
      lineColor: '#2196F3',
      markerType: 'square',
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.WEATHER_READINGS.RAINFALL')}: ${val.toFixed(1)}${
            this.fluidDepthNormalUnit
          }`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;
    const irrigation = {
      //'id': 'Irrigation',
      valueField: 'irrigationDb',
      title: this._languageService.instant('COMMON.IRRIGATION'),
      valueAxis: mmAxis,
      type: 'column',
      clustered: false,
      columnWidth: 0.6,
      alphaField: 'alpha',
      fillAlphas: 1,
      lineColor: '#1A237E',
      markerType: 'square',
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.IRRIGATION')}: ${val.toFixed(2)}${this.fluidDepthNormalUnit}`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;
    const soilMoisture = {
      //'id': 'soilMoisture',
      valueField: 'soilMoisture',
      bullet: 'round',
      bulletBorderAlpha: 1,
      hideBulletsCount: 50,
      title: this._languageService.instant('COMMON.SOIL_MOISTURE'),
      valueAxis: smAxis,
      type: 'line',
      useLineColorForBulletBorder: true,
      lineColor: '#824242',
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.PREDICTED')}: ${val.toFixed(1)}%`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;

    const irrigationChartOption = {
      theme: 'light',
      type: 'serial',
      dataProvider: this.dataProvider,
      legend: {
        useGraphSettings: true,
        position: 'top',
        horizontalGap: 0,
        spacing: 0,
        marginLeft: 0,
        marginRight: 0,
        valueWidth: 0,
      } as AmCharts.AmLegend,
      valueAxes: [mmAxis, smAxis],
      startDuration: 0,
      graphs: [soilMoistureHighRange, soilMoistureOkRange, soilMoistureLowRange, rainFall, irrigation, soilMoisture],

      plotAreaFillAlphas: 0.1,
      categoryField: 'localeDate',
      categoryAxis: {
        gridPosition: 'start',
        gridAlpha: 0,
        parseDates: true,
        axisColor: '#DADADA',
        dateFormats: [
          { period: 'DD', format: 'EEE DD' },
          { period: 'WW', format: 'EEE DD' },
          { period: 'MM', format: 'EEE DD' },
          { period: 'YYYY', format: 'EEE DD' },
        ],
        dashLength: 1,
      } as AmCharts.CategoryAxis,
    } as AmCharts.AmSerialChart;
    this.siteIrrigationSummaryChart = AmCharts.makeChart('site-irrigation-chart', irrigationChartOption) as AmCharts.AmSerialChart;
    this.addIrrigationChartGuide(this.siteIrrigationSummaryChart);
  }

  private addIrrigationChartGuide(chart: AmCharts.AmSerialChart) {
    if (!chart) {
      return;
    }

    const guideLine: AmCharts.Guide = new AmCharts.Guide();

    guideLine.date = this._dayNumberService.convertBrowserDateToLocaleDate(new Date(), this.account.timezoneId);
    guideLine.above = true;
    guideLine.lineColor = '#444444';
    guideLine.lineAlpha = 0.4;
    guideLine.inside = true;
    guideLine.label = this._languageService.instant('COMMON.TODAY') + ' ' + guideLine.date.toTimeString().slice(0, 5);
    guideLine.labelRotation = 90;
    guideLine.position = 'top';
    guideLine.dashLength = 0;

    if (chart.categoryAxis.addGuide !== undefined) {
      // remove any existing guides
      for (let idx = chart.categoryAxis.guides.length - 1; idx >= 0; idx--) {
        chart.categoryAxis.removeGuide(chart.categoryAxis.guides[idx]);
      }
      chart.categoryAxis.addGuide(guideLine);
    } else {
      //  console.log('check categoryAxis');
    }
    chart.validateNow();
  }
}

angular.module('fuse').component('siteIrrigationSummaryChart', new SiteIrrigationSummaryChartComponent());
