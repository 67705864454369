import * as angular from 'angular';
import { Asset } from 'src/app/_DBContext/Asset';
import { ApiService } from '@services/api.service';
import { LocalStorageService } from '@services/local-storage.service';

/**
 * Allows user to select current visible Group, so that only Sites, Sensors and Dashboard items from that group will be displayed.
 * For page to respond to changes in the filter, it must have a function called loadGroupData() which group related dependencies.
 * Current 'groupId' is stored in LocalStorageService 'accountList' for given 'accountId'.
 */
class GroupsFilterComponent implements angular.IComponentOptions {
  bindings = {
    parent: '=',
    refreshCount: '<',
  };
  controller = GroupsFilterController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/common/groups-filter.html';
}

class GroupsFilterController {
  public parent;

  public groups: Asset[];
  public group: Asset;
  public nameFilter: string = '';
  public default: Asset;

  private _apiService: ApiService;
  private _localStorageService: LocalStorageService;

  constructor(
    ApiService: ApiService,
    LocalStorageService: LocalStorageService,
  ) {
    this._localStorageService = LocalStorageService;
    this._apiService = ApiService;
    this.default = { AssetId: 0, Name: '', Status: 'Active' } as Asset;
  }

  $onChanges(changes) {
    if (!this.groups?.length || changes.refreshCount?.currentValue > 0) {
      this.loadGroups();
    }
  }

  public changeGroup(group: Asset) {
    this.group = group;

    if (this._localStorageService.hasGroupChanged(group.AssetId)) {
      this._localStorageService.setGroupId(group.AssetId);
      this.parent.loadGroupData();
    }
  }

  private loadGroups() {
    this._apiService.getGeneric('group/getGroupsList', null, (data) => {
      this.groups = data.map((g) => ({
        AssetId: g.assetId,
        Name: g.assetName,
        Status: g.status,
      } as Asset));

      const groupId = this._localStorageService.getGroupId();

      this.group = this.groups.find((g) => g.AssetId == groupId) ?? this.default;

      if (this.group.AssetId === this.default.AssetId) {
        this._localStorageService.setGroupId(0);
      }
    });
  }
}

angular.module('fuse').component('groupsFilter', new GroupsFilterComponent());
