import * as angular from 'angular';

angular.module('fuse').directive('limitToMin', () => {
  return {
    link: (scope, element, attributes) => {
      element.on('keydown keyup', (e) => {
        if (
          Number(element.val()) < Number(attributes.min) &&
          e.keyCode != 46 && // delete
          e.keyCode != 8 // backspace
        ) {
          e.preventDefault();
          element.val(attributes.min);
        }
      });
    },
  };
});
