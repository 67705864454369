import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { NutrientFields } from './NutrientFields';
import { WorkAgent } from './WorkAgent';

export class NutrientSetting extends breezeClass implements breeze.Entity {
  Id: number;
  AssetId: number;
  Percentage: number;
  Name: string;
  Noots: NutrientFields;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  AuthUser: AuthUser;
  NutrientProfile: Asset;
  WorkAgent: WorkAgent;
}
