import { breezeClass } from '@common/models/baseClass';

export class Property extends breezeClass implements breeze.Entity {
  Id: number;
  Label: string;
  PropertyKey: string;
  Required: boolean;
  UnitBaseClassId: number;
  ScaleId: number;
  DecimalPlaces: number;
  RangeFrom: string;
  RangeTo: string;
  Choices: string[];
  DefaultValue: string;
  Type: string;
  MultiSelect: number;
}
