import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { LanguageService } from '@services/language.service';

type SchedulerOption = {
  value: number;
  displayValue: string;
}

/* Allows user to select up to 3 hours (00-23) and 1 minute (00-59) to be combined (eg. 01:40, 04:40, 13:40) as data collection runtimes. */
class SchedulerTimesComponent implements angular.IComponentOptions {
  bindings = {
    scheduler: '<',
    refreshCount: '<',
    updateScheduler: '<',
  };
  controller = SchedulerTimesController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/common/scheduler-times.html';
}

class SchedulerTimesController implements angular.IController {
  public scheduler: fuse.equipmentScheduler;
  public hoursOptions = this.schedulerOptions('hours');
  public minutesOptions = this.schedulerOptions('minutes');
  public hours: number[];
  public minute: number | null;
  public schedulerDetails: string;
  public hasHoursLimitReached: boolean;
  public hasSchedulerWarning: boolean;

  private _languageService: LanguageService;

  private _hoursLimit = 3;
  private updateScheduler: (scheduler: { hours: number[], minute: number }) => void;

  constructor(
    LanguageService: LanguageService,
  ) {
    this._languageService = LanguageService;
  }

  $onChanges(changes) {
    if (this.scheduler) {
      this.hours = this.scheduler.hours?.map((x) => parseInt(x)) ?? [];
      this.minute = this.scheduler.minutes ? parseInt(this.scheduler.minutes) : null;
    }

    if (changes.refreshCount?.currentValue > 0) {
      this.updateSchedulerDetails();
    }
  }

  public checkHoursLimit(): void {
    this.hasHoursLimitReached = this.hours.length >= this._hoursLimit;
  }

  public onChange(): void {
    this.checkHoursLimit();
    this.updateSchedulerDetails();

    // IMPORTANT: Call the parent component's 'updateScheduler' function last to avoid unneeded component re-renders.
    this.updateScheduler({ hours: this.hours, minute: this.minute });
  }

  // Numbers from 00-23 (hours) or 0-59 (minutes) with a single digit values padded with an extra '0'.
  private schedulerOptions(period = 'hours'): SchedulerOption[] {
    const maxValue = period == 'hours' ? 24 : 60;
    let options = Array(maxValue).fill('').map((_, i) => ({ value: i, displayValue: i.toString().padStart(2, '0') }));

    if (period != 'hours') {
      options = [{ value: null, displayValue: '' }].concat(options);
    }

    return options;
  }

  private updateSchedulerDetails(): void {
    const hasHours = !!this.hours.length;
    const hasMinute = this.minute != null && this.minute >= 0;
    let details: string;

    this.hasSchedulerWarning = false;

    if (hasHours && hasMinute) {
      details = ArrayUtils.sortNumbers(this.hours)
        .map((hr) => `${hr.toString().padStart(2, '0')}:${this.minute.toString().padStart(2, '0')}`)
        .join(', ');
    } else if (hasHours || hasMinute) {
      if (hasHours) {
        details = this._languageService.instant('AC.EQUIP.SCHEDULE_SELECT_MINS');
      } else if (hasMinute) {
        details = this._languageService.instant('AC.EQUIP.SCHEDULE_SELECT_HOURS');
      } else {
        details = this._languageService.instant('AC.EQUIP.SCHEDULE_PROMPT');
      }

      this.hasSchedulerWarning = true;
    } else {
      details = '';
    }

    this.schedulerDetails = details;
  }
}

angular.module('fuse').component('schedulerTimes', new SchedulerTimesComponent());
