import { breezeClass } from '@common/models/baseClass';
import { AuthUser } from './AuthUser';
import { Crop } from './Crop';
import { WorkAgent } from './WorkAgent';

export class CropCoefficient extends breezeClass implements breeze.Entity {
  Id: number;
  CropId: number;
  GrowthPhase: number;
  Description: string;
  GrowthPhaseDuration: number;
  WaterCoefficient: number;
  NutrientUse: number;
  SlopeOption: string;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  AuthUser: AuthUser;
  Crop: Crop;
  WorkAgent: WorkAgent;
  WorkingSoilDepth: number;
}
