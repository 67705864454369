import { breezeClass } from '@common/models/baseClass';

export class SiteMonth extends breezeClass implements breeze.Entity {
  AssetId: number;
  BudgetId: number;
  DayNumber: number;
  EndDayNumber: number;
  Water_KL_Budget: number;
  Water_KL_OriginalBudget: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
}
