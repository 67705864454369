import { Asset } from './Asset';
import { AssetAttachment } from './AssetAttachment';
import { AssetClass } from './AssetClass';
import { AuthAccount } from './AuthAccount';
import { AuthAccountClass } from './AuthAccountClass';
import { AuthClient } from './AuthClient';
import { AuthRefreshToken } from './AuthRefreshToken';
import { AuthUser } from './AuthUser';
import { AuthUserLogin } from './AuthUserLogin';
import { Budget } from './Budget';
import { Contact } from './Contact';
import { Crop } from './Crop';
import { CropCoefficient } from './CropCoefficient';
import { CropTolerance } from './CropTolerance';
import { CurrencyISO4217 } from './CurrencyISO4217';
import { DataInput } from './DataInput';
import { DataProvider } from './DataProvider';
import { DataProviderSubscription } from './DataProviderSubscription';
import { Fertiliser } from './Fertiliser';
import { FertiliserPrice } from './FertiliserPrice';
import { IdentityRole } from './IdentityRole';
import { IdentityUserClaim } from './IdentityUserClaim';
import { IdentityUserLogin } from './IdentityUserLogin';
import { IdentityUserRole } from './IdentityUserRole';
import { IrrigationPlan } from './IrrigationPlan';
import { IrrigationPlanOverride } from './IrrigationPlanOverride';
import { IrrigationProtocol } from './IrrigationProtocol';
import { MarketRegions } from './MarketRegions';
import { NutrientFields } from './NutrientFields';
import { NutrientSetting } from './NutrientSetting';
import { ObsHealthIndex } from './ObsHealthIndex';
import { ObsNutrients } from './ObsNutrients';
import { ObsSoilMoisture } from './ObsSoilMoisture';
import { ObsWaterFlow } from './ObsWaterFlow';
import { ObsWaterStore } from './ObsWaterStore';
import { ObsWeatherRollup } from './ObsWeatherRollup';
import { Property } from './Property';
import { PropertyValue } from './PropertyValue';
import { Sensor } from './Sensor';
import { Site } from './Site';
import { SiteAsset } from './SiteAsset';
import { SiteBudget } from './SiteBudget';
import { SiteBudgetFertiliser } from './SiteBudgetFertiliser';
import { SiteDailySummary } from './SiteDailySummary';
import { SiteMonth } from './SiteMonth';
import { SiteSettingsCrop } from './SiteSettingsCrop';
import { SiteSettingsNutrients } from './SiteSettingsNutrients';
import { SiteSettingsSoil } from './SiteSettingsSoil';
import { SiteSettingsWater } from './SiteSettingsWater';
import { SiteWeeklyFertiliser } from './SiteWeeklyFertiliser';
import { SoilTexture } from './SoilTexture';
import { SysAnalyte } from './SysAnalyte';
import { SysAnalyteClient } from './SysAnalyteClient';
import { SysElement } from './SysElement';
import { SysMessageLog } from './SysMessageLog';
import { Unit } from './Unit';
import { UnitBaseClass } from './UnitBaseClass';
import { ValueRange } from './ValueRange';
import { WaterStore } from './WaterStore';
import { WorkAgent } from './WorkAgent';
import { WorkAgentCollector } from './WorkAgentCollector';
import { WaterBudget } from './WaterBudget';
import { WaterBudgetMonth } from './WaterBudgetMonth';
import { WaterBudgetSite } from './WaterBudgetSite';
import { WaterBudgetSiteMonth } from './WaterBudgetSiteMonth';

export class _RegistrationHelper {
  static register(metadataStore) {
    metadataStore.registerEntityTypeCtor('Asset', Asset);
    metadataStore.registerEntityTypeCtor('AssetClass', AssetClass);
    metadataStore.registerEntityTypeCtor('AssetAttachment', AssetAttachment);
    metadataStore.registerEntityTypeCtor('AuthAccount', AuthAccount);
    metadataStore.registerEntityTypeCtor('AuthAccountClass', AuthAccountClass);
    metadataStore.registerEntityTypeCtor('AuthClient', AuthClient);
    metadataStore.registerEntityTypeCtor('AuthRefreshToken', AuthRefreshToken);
    metadataStore.registerEntityTypeCtor('AuthUser', AuthUser);
    metadataStore.registerEntityTypeCtor('AuthUserLogin', AuthUserLogin);
    metadataStore.registerEntityTypeCtor('Budget', Budget);
    metadataStore.registerEntityTypeCtor('Contact', Contact);
    metadataStore.registerEntityTypeCtor('Crop', Crop);
    metadataStore.registerEntityTypeCtor('CropCoefficient', CropCoefficient);
    metadataStore.registerEntityTypeCtor('CropTolerance', CropTolerance);
    metadataStore.registerEntityTypeCtor('CurrencyISO4217', CurrencyISO4217);
    metadataStore.registerEntityTypeCtor('DataInput', DataInput);
    metadataStore.registerEntityTypeCtor('DataProvider', DataProvider);
    metadataStore.registerEntityTypeCtor('DataProviderSubscription', DataProviderSubscription);
    metadataStore.registerEntityTypeCtor('Fertiliser', Fertiliser);
    metadataStore.registerEntityTypeCtor('FertiliserPrice', FertiliserPrice);
    metadataStore.registerEntityTypeCtor('IdentityRole', IdentityRole);
    metadataStore.registerEntityTypeCtor('IdentityUserClaim', IdentityUserClaim);
    metadataStore.registerEntityTypeCtor('IdentityUserLogin', IdentityUserLogin);
    metadataStore.registerEntityTypeCtor('IdentityUserRole', IdentityUserRole);
    metadataStore.registerEntityTypeCtor('IrrigationPlan', IrrigationPlan);
    metadataStore.registerEntityTypeCtor('IrrigationPlanOverride', IrrigationPlanOverride);
    metadataStore.registerEntityTypeCtor('IrrigationProtocol', IrrigationProtocol);
    metadataStore.registerEntityTypeCtor('MarketRegions', MarketRegions);
    metadataStore.registerEntityTypeCtor('NutrientSetting', NutrientSetting);
    metadataStore.registerEntityTypeCtor('NutrientFields', NutrientFields);
    metadataStore.registerEntityTypeCtor('ObsHealthIndex', ObsHealthIndex);
    metadataStore.registerEntityTypeCtor('ObsNutrients', ObsNutrients);
    metadataStore.registerEntityTypeCtor('ObsSoilMoisture', ObsSoilMoisture);
    metadataStore.registerEntityTypeCtor('ObsWaterFlow', ObsWaterFlow);
    metadataStore.registerEntityTypeCtor('Property', Property);
    metadataStore.registerEntityTypeCtor('PropertyValue', PropertyValue);
    metadataStore.registerEntityTypeCtor('ObsWaterStore', ObsWaterStore);
    metadataStore.registerEntityTypeCtor('ObsWeatherRollup', ObsWeatherRollup);
    metadataStore.registerEntityTypeCtor('Sensor', Sensor);
    metadataStore.registerEntityTypeCtor('Site', Site);
    metadataStore.registerEntityTypeCtor('SiteAsset', SiteAsset);
    metadataStore.registerEntityTypeCtor('SiteBudget', SiteBudget);
    metadataStore.registerEntityTypeCtor('SiteBudgetFertiliser', SiteBudgetFertiliser);
    metadataStore.registerEntityTypeCtor('SiteDailySummary', SiteDailySummary);
    metadataStore.registerEntityTypeCtor('SiteWeeklyFertiliser', SiteWeeklyFertiliser);
    metadataStore.registerEntityTypeCtor('SoilTexture', SoilTexture);
    metadataStore.registerEntityTypeCtor('SysAnalyte', SysAnalyte);
    metadataStore.registerEntityTypeCtor('SysAnalyteClient', SysAnalyteClient);
    metadataStore.registerEntityTypeCtor('SysElement', SysElement);
    metadataStore.registerEntityTypeCtor('SiteSettingsCrop', SiteSettingsCrop);
    metadataStore.registerEntityTypeCtor('SiteMonth', SiteMonth);
    metadataStore.registerEntityTypeCtor('SiteSettingsNutrients', SiteSettingsNutrients);
    metadataStore.registerEntityTypeCtor('SiteSettingsSoil', SiteSettingsSoil);
    metadataStore.registerEntityTypeCtor('SiteSettingsWater', SiteSettingsWater);
    metadataStore.registerEntityTypeCtor('SysMessageLog', SysMessageLog);
    metadataStore.registerEntityTypeCtor('Unit', Unit);
    metadataStore.registerEntityTypeCtor('UnitBaseClass', UnitBaseClass);
    metadataStore.registerEntityTypeCtor('ValueRange', ValueRange);
    metadataStore.registerEntityTypeCtor('WaterBudget', WaterBudget);
    metadataStore.registerEntityTypeCtor('WaterBudgetMonth', WaterBudgetMonth);
    metadataStore.registerEntityTypeCtor('WaterBudgetSite', WaterBudgetSite);
    metadataStore.registerEntityTypeCtor('WaterBudgetSiteMonth', WaterBudgetSiteMonth);
    metadataStore.registerEntityTypeCtor('WaterStore', WaterStore);
    metadataStore.registerEntityTypeCtor('WorkAgent', WorkAgent);
    metadataStore.registerEntityTypeCtor('WorkAgentCollector', WorkAgentCollector);
  }
}
