// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#nutrients-form md-input-container {
  margin: 0;
  padding: 0 16px 16px 0;
}
#nutrients-form md-input-container .md-errors-spacer {
  min-height: 0px;
}
#nutrients-form md-input-container .md-input {
  width: 120px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/_components/nutrients/nutrients-form.scss"],"names":[],"mappings":"AACI;EACI,SAAA;EACA,sBAAA;AAAR;AACQ;EACI,eAAA;AACZ;AACQ;EACI,YAAA;EACA,kBAAA;AACZ","sourcesContent":["#nutrients-form{\r\n    md-input-container {\r\n        margin: 0;\r\n        padding: 0 16px 16px 0;\r\n        .md-errors-spacer {\r\n            min-height: 0px;\r\n        }\r\n        .md-input {\r\n            width: 120px;\r\n            text-align: center;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
