import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { CalculatedResults } from '@common/nutrients.interface';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { uomUnit } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';
import { ObsNutrients } from 'src/app/_DBContext/ObsNutrients';
import { SysAnalyteClient } from 'src/app/_DBContext/SysAnalyteClient';

class AnalyteFormComponent implements angular.IComponentOptions {
  bindings = {
    parentPage: '@',
    assetId: '<',
    defaultUnit: '<',
    fertiliserConcentrationMode: '<',
    fertiliserSpecificGravity: '<',
    rejectChanges: '<',
    calculatedResults: '=',
    isIncludeInAnalytes: '<',
    isIncludeInFertiliser: '<',
    obsNutrient: '=',
    reloadChanges: '<',
    editMode: '=',
    onChange: '&',
    uomUnit: '<',
  };
  controller = AnalyteFormController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/nutrients/analyte-form.component.html';
}

class AnalyteFormController extends BaseController {
  public selectedUnit;
  public parentPage: string;
  public makeFixed: boolean = false;
  public assetId: number;
  public defaultUnit: string;
  public fertiliserConcentrationMode: string;
  public fertiliserSpecificGravity: number;
  public calculatedResults: CalculatedResults;
  public isIncludeInFertiliser: boolean;
  public isIncludeInAnalytes: boolean;
  public analyteSigns: any[] = [];
  public showUnits: boolean;
  public onChange: Function;
  public editMode: boolean;
  public obsNutrient: ObsNutrients;
  private analyteValuesWeight: any[] = [];
  private analytesLst: SysAnalyteClient[];

  public uomUnit: uomUnit;

  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _languageService: LanguageService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this.setEditPermission(ApplicationPrivileges.NutrientsAnalytesFull);

    this._q = $q;
    this._languageService = LanguageService;
    this._mdDialog = $mdDialog;

    this.entityManager = DataEntityService.manager;
    this.showUnits = true;
  }

  $onInit() {
    this.makeFixed = true;

    if (this.parentPage == 'fertiliser') {
      this.showUnits = false;
    }
  }

  $onChanges(changes) {
    if (changes.rejectChanges?.currentValue > 0) {
      if (angular.isDefined(this.obsNutrient) && this.obsNutrient.Noots.Analytes !== null) {
        const combinedAnalytes = JSON.parse(this.obsNutrient.Noots.Analytes) as Analyte[];
        this.obsNutrient.Noots.JSONAnalytes = combinedAnalytes.filter((analyte) => {
          return this.isCalculatedAnalyte(analyte.BaseClassId);
        });
        this.obsNutrient.Noots.JSONDisplayAnalytes = combinedAnalytes.filter((analyte) => {
          return !this.isCalculatedAnalyte(analyte.BaseClassId);
        });
      }
    }

    if (changes.reloadChanges?.currentValue > 0) {
      const promises = [];
      promises.push(this.fetchSelectedAnalytesClient());
      this._q.all(promises).then((data: any) => {
        if (this.obsNutrient.Noots.Analytes) {
          const combinedAnalytes = JSON.parse(this.obsNutrient.Noots.Analytes);

          this.obsNutrient.Noots.JSONAnalytes = ArrayUtils.sortByString(
            combinedAnalytes.filter((analyte: Analyte) => !analyte.UnitString),
            (x) => x.Name,
          );

          this.obsNutrient.Noots.JSONDisplayAnalytes = ArrayUtils.sortByString(
            combinedAnalytes.filter((analyte: Analyte) => analyte.UnitString),
            (x) => x.Name,
          );

        } else {
          this.obsNutrient.Noots.JSONAnalytes = [];
          this.obsNutrient.Noots.JSONDisplayAnalytes = [];
        }
        this.analytesLst = data[0].results as SysAnalyteClient[];
        let unitUsed = undefined;
        if (this.parentPage == 'sampling') unitUsed = { id: 0, value: 'mg/L' };
        this.calculate();
        // Detect new analytes
        this.detectNewAnalytes(unitUsed);
      });
    }
    if (
      angular.isDefined(this.obsNutrient) &&
      changes.fertiliserConcentrationMode &&
      !!changes.fertiliserConcentrationMode.currentValue
    ) {
      this.calculate();
    }
    if (angular.isDefined(this.obsNutrient) && this.defaultUnit == 'L' && changes.fertiliserSpecificGravity?.currentValue > 0) {
      this.calculate(true);
    }
  }

  private detectNewAnalytes(unitUsed: string) {
    let newNCAnalytes;
    const newAnalytes = this.analytesLst.filter((newAnalyte) => {
      const checkAnalyte = (analyte: Analyte) => {
        return analyte.Name === newAnalyte.SysAnalyte.Name;
      };
      return (
        this.obsNutrient.Noots.JSONAnalytes.findIndex(checkAnalyte) === -1 &&
        this.isCalculatedAnalyte(newAnalyte.SysAnalyte.UnitBaseClassId)
      );
    });

    if (this.parentPage !== 'fertiliser') {
      // Non-calculated analytes
      newNCAnalytes = this.analytesLst.filter((newAnalyte) => {
        const checkAnalyte = (analyte: Analyte) => {
          return analyte.Name === newAnalyte.SysAnalyte.Name;
        };
        return (
          this.obsNutrient.Noots.JSONDisplayAnalytes.findIndex(checkAnalyte) === -1 &&
          !this.isCalculatedAnalyte(newAnalyte.SysAnalyte.UnitBaseClassId)
        );
      });
    }
    const cancelledCallback = () => {};
    const confirmCallback = () => {
      // add analytes
      newAnalytes.forEach((analyte) => {
        this.obsNutrient.Noots.JSONAnalytes.push({
          Name: analyte.SysAnalyte.Name,
          Value: 0,
          Unit: unitUsed,
          BaseClassId: analyte.SysAnalyte.UnitBaseClassId,
        } as Analyte);
      });
      if (this.parentPage !== 'fertiliser') {
        newNCAnalytes.forEach((analyte) => {
          this.obsNutrient.Noots.JSONDisplayAnalytes.push({
            Name: analyte.SysAnalyte.Name,
            Value: 0,
            UnitString: analyte.SysAnalyte.Unit.Name,
            BaseClassId: analyte.SysAnalyte.UnitBaseClassId,
          } as Analyte);
        });
      }
    };

    const finalCallback = () => {
      this.obsNutrient.Noots.JSONAnalytes = ArrayUtils.sortByString(this.obsNutrient.Noots.JSONAnalytes, (x) => x.Name);
      this.obsNutrient.Noots.JSONDisplayAnalytes = ArrayUtils.sortByString(this.obsNutrient.Noots.JSONDisplayAnalytes, (x) => x.Name);

      this.calculate();
      this.updateAnalytes();
      // this.entityManager.saveChanges(); //Fix for inital values for anlytes saved before page load
    };

    if (newAnalytes?.length || newNCAnalytes?.length) {
      const confirm = this.confirmAnalyteChanges(newAnalytes, newNCAnalytes);
      if (confirm) {
        confirm.then(confirmCallback, cancelledCallback).finally(finalCallback);
      }
    }
  }

  private isCalculatedAnalyte(baseClassId: number) {
    return baseClassId == 1 || baseClassId == 6;
  }

  private confirmAnalyteChanges(newAnalytes: SysAnalyteClient[], newNCAnalytes: SysAnalyteClient[]) {
    const shouldSkipConfirmation =
      this.isReadOnly || this.assetId == 0 || this.permissionService.accountId != this.obsNutrient.Asset.OwnerAccountId;

    if (shouldSkipConfirmation) {
      return;
    }

    //build text content
    let textContent = newAnalytes.map((a) => this._languageService.instant('NUTR.CHEM.' + a.SysAnalyte.Name.trim())).join(', ');

    if (this.parentPage !== 'fertiliser') {
      textContent += newNCAnalytes.map((a) => this._languageService.instant('NUTR.CHEM.' + a.SysAnalyte.Name.trim())).join(', ');
    }

    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('NUTR.ANA.ADD_TITLE'))
      .textContent(textContent)
      .ariaLabel(this._languageService.instant('NUTR.ANA.ADD_TITLE'))
      .ok(this._languageService.instant('COMMON.ADD'))
      .cancel(this._languageService.instant('COMMON.CANCEL'));

    return this._mdDialog.show(confirm);
  }

  private calculate(isSpecificGravityChange: boolean = false) {
    this.obsNutrient.Noots.JSONAnalytes.map((analyte, index) => {
      if (isSpecificGravityChange && this.fertiliserConcentrationMode == 'w/w' && this.fertiliserSpecificGravity > 0) {
        analyte.Value = this.analyteValuesWeight[index] * this.fertiliserSpecificGravity;
      }
      if (analyte.Value === -9999) {
        //#IG 183: The new logic to identify N/A from database is -9999. Obsnutrients doesn't accept null '
        this.analyteSigns[analyte.Name] = 'NotTested';
        analyte.DisplayValue = 0;
      } else if (analyte.Value < 0) {
        this.analyteSigns[analyte.Name] = 'LessThan';
        analyte.DisplayValue = Math.abs(analyte.Value);
      } else {
        //if (fromdb.Value >= 0)
        this.analyteSigns[analyte.Name] = 'blank';
        analyte.DisplayValue = analyte.Value;
      }
    });
    if (isSpecificGravityChange) {
      this.updateAnalytes();
    }
    this.analyteValuesWeight = this.obsNutrient.Noots.JSONAnalytes.map((analyte) => {
      if (analyte.DisplayValue && this.fertiliserSpecificGravity > 0) {
        return analyte.DisplayValue / this.fertiliserSpecificGravity;
      } else {
        return null;
      }
    });
  }

  public fetchSelectedAnalytesClient(): Promise<breeze.QueryResult> {
    let pred: breeze.Predicate;
 
    if (this.isIncludeInFertiliser) {
      pred = breeze.Predicate.create('IsIncludeInFertiliser', '==', this.isIncludeInFertiliser);
    }

    if (this.isIncludeInAnalytes) {
      pred = breeze.Predicate.create('IsIncludeInAnalytes', '==', this.isIncludeInAnalytes);
    }

    pred = pred.or(breeze.Predicate.create('IsIncludeForDisplay', '==', true));

    return breeze.EntityQuery.from('SysAnalyteClient')
      .expand('SysAnalyte.Unit')
      .withParameters({ accountId: this.accountId })
      .where(pred)
      .using(this.entityManager)
      .execute();
  }

  public updateAnalytes() {
    angular.forEach(this.obsNutrient.Noots.JSONAnalytes, (analyte, index) => {
      if (this.fertiliserSpecificGravity > 0) {
        if (this.fertiliserConcentrationMode == 'w/w') {
          // Calculate w/v from w/w input
          analyte.DisplayValue = this.analyteValuesWeight[index] * this.fertiliserSpecificGravity;
        } else if (this.fertiliserConcentrationMode == 'w/v') {
          // Calculate w/w from w/v input
          this.analyteValuesWeight[index] = analyte.DisplayValue / this.fertiliserSpecificGravity;
        }
      }

      if (analyte.DisplayValue >= 0 || !analyte.DisplayValue) {
        switch (this.analyteSigns[analyte.Name]) {
          case 'LessThan':
            analyte.Value = analyte.DisplayValue * -1;
            break;
          case 'NotTested': //#IG 183: The new logic to identify N/A from database is -9999. Obsnutrients doesn't accept null '
            analyte.Value = -9999;
            analyte.DisplayValue = 0;
            break;
          default:
            analyte.Value = analyte.DisplayValue;
            break;
        }
      }

      if (this.fertiliserSpecificGravity > 0) {
        this.analyteValuesWeight[index] = analyte.DisplayValue / this.fertiliserSpecificGravity;
      }
    });

    //Analyte is storing as a Json string in db.
    let combinedAnalytes = [];
    combinedAnalytes = angular.copy(this.obsNutrient.Noots.JSONAnalytes);
    if (this.parentPage !== 'fertiliser') {
      if (this.obsNutrient.Noots.JSONDisplayAnalytes?.length) {
        combinedAnalytes = angular.copy(this.obsNutrient.Noots.JSONAnalytes.concat(this.obsNutrient.Noots.JSONDisplayAnalytes));
      }
    }

    // slight data clean up
    combinedAnalytes.map((cAnalyte) => {
      delete cAnalyte.$$hashKey;
      //delete cAnalyte.DisplayValue;
    });

    const noots_Analytes_JsonString = JSON.stringify(combinedAnalytes);
    if (noots_Analytes_JsonString.length) {
      if (angular.isDefined(this.obsNutrient)) {
        this.obsNutrient.Noots.Analytes = noots_Analytes_JsonString;
      }
    }
    this.onChange(); // call to parent function to perform actions;
  }

  public showDeleteDialog(name: string) {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('NUTR.ANA.EXCLUDE_ANALYTE'))
      .textContent(this._languageService.instant('NUTR.ANA.EXCLUDE_CONFIRM', { n: name }))
      .ariaLabel(this._languageService.instant('NUTR.ANA.EXCLUDE_ANALYTE'))
      .ok(this._languageService.instant('COMMON.EXCLUDE'))
      .cancel(this._languageService.instant('COMMON.CANCEL'));

    const cancelledCallback = () => {};
    const confirmCallback = () => {
      const checkAnalyte = (analyte: Analyte) => {
        return analyte.Name === name;
      };
      // exclude analyte
      const foundAnalyteIndex = this.obsNutrient.Noots.JSONAnalytes.findIndex(checkAnalyte);
      if (foundAnalyteIndex > -1) {
        this.obsNutrient.Noots.JSONAnalytes.splice(foundAnalyteIndex, 1);
      }
    };

    const finalCallback = () => {
      this.calculate();
      this.updateAnalytes();
    };

    this._mdDialog.show(confirm).then(confirmCallback, cancelledCallback).finally(finalCallback);
  }
}

// !! Analytes interface has backend relationship with Analytes class in indicina.swan.businessservices\prediction\nutrientcalc.cs
export interface Analyte {
  Name: string;
  Value: number;
  DisplayValue: number;
  CustomValue: string; //e.g. any unitbase class not W/W and W/V
  UnitString: string; // for non-calculated analytes
  Unit: any;
  Sign: string;
  BaseClassId: number;
}

angular.module('app.nutrients').component('swanAnalyteForm', new AnalyteFormComponent());
