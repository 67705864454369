import * as moment from 'moment';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { WorkAgent } from './WorkAgent';
import { breezeClass } from '@common/models/baseClass';
import { DecimalDayService } from '@services/decimal-day.service';

export class ObsWaterFlow extends breezeClass implements breeze.Entity {
  /// <code> Place custom code between <code> tags
  private _dds: DecimalDayService;

  constructor() {
    super();
    this._dds = new DecimalDayService();
  }

  private _localDate: Date;

  get localDate(): Date {
    if (this._localDate == null && this.dayDisplayYMD != null) {
      this._localDate = moment(this.dayDisplayYMD, 'YYYY-MM-DD').toDate();
    }
    return this._localDate;
  }

  set localDate(date: Date) {
    this._localDate = date;
    this.dayDisplayYMD = moment(date).format('YYYY-MM-DD');
    this.dayNumber = -999;
  }

  private _durationHrsMins: string;

  get durationHrsMins(): string {
    if (this._durationHrsMins == null && this.Duration != null) {
      this._durationHrsMins = this._dds.daysToHHmm(this.Duration); //this.Duration.toString();
    }
    return this._durationHrsMins;
  }

  set durationHrsMins(value: string) {
    if (value != null) {
      this._durationHrsMins = value;
      this.Duration = this._dds.HHmmToDecimalDays(value);
    }
  }

  public toggleLock() {
    this.Locked = !this.Locked;
  }

  // Generated code. Do not place code below this line.
  ObsWaterFlowId: number;
  VolumeKL: number;
  Duration: number;
  AssetId: number;
  DateTime: Date;
  dayNumber: number;
  dayDisplayMMdd: string;
  dayDisplayYMD: string;
  dayOfWeek: string;
  QualityFactor: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Locked: boolean;
  Asset: Asset;
  AuthUser: AuthUser;
  WorkAgent: WorkAgent;
}
