import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';

export class IrrigationProtocol extends breezeClass implements breeze.Entity {
  Id: number;
  AssetId: number;
  Asset: Asset;
  Name: string;
  WindowStart: string;
  WindowEnd: string;
  Pulses: number;
  MinimumPulseMinutes: string;
  GapMinutes: string;
  FertigationPulse: string;
}
