import { breezeClass } from '@common/models/baseClass';

export class WaterBudget extends breezeClass implements breeze.Entity {
  Id: number;
  AuthAccountId: number;
  Name: string;
  Status: string;
  CropId: number;
  StartDayNumber: number;
  EndDayNumber: number;
  EtoWeatherStationId?: number;
  RainWeatherStationId?: number;
  DefaultSystemEfficiencyPct: number;
  DefaultUsefulRainfallPct: number;
  DefaultKLPerHa: number;
  AppliedToSitesWhen: Date;
  AppliedToSitesBy: string;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  CreatePlanFromBudget: boolean;
  WeatherSource: string;
}
