import * as angular from 'angular';
import { Analyte } from './analyte-form.component';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';
import { ObsNutrients } from 'src/app/_DBContext/ObsNutrients';
import { SysAnalyteClient } from 'src/app/_DBContext/SysAnalyteClient';

class NonCalculatedAnalyteFormComponent implements angular.IComponentOptions {
  bindings = {
    parentPage: '@',
    assetId: '<',
    defaultUnit: '<',
    rejectChanges: '<',
    obsNutrient: '=',
    reloadChanges: '<',
    editMode: '=',
    onChange: '&',
  };
  controller = NonCalculatedAnalyteFormController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/nutrients/noncalculated-analyte-form.component.html';
}

class NonCalculatedAnalyteFormController extends BaseController {
  public parentPage: string;
  public assetId: number;

  public obsNutrient: ObsNutrients;
  public onChange: Function;

  private _languageService: LanguageService;
  private _mdDialog;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.NutrientsAnalytesFull);

    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
  }

  public updateAnalytes() {
    //Analyte is storing as a Json string in db.
    let combinedAnalytes = [];
    combinedAnalytes = angular.copy(this.obsNutrient.Noots.JSONAnalytes);

    if (this.obsNutrient.Noots.JSONDisplayAnalytes?.length) {
      combinedAnalytes = angular.copy(this.obsNutrient.Noots.JSONAnalytes.concat(this.obsNutrient.Noots.JSONDisplayAnalytes));
    }

    // slight data clean up
    combinedAnalytes.map((cAnalyte) => {
      delete cAnalyte.$$hashKey;
      delete cAnalyte.DisplayValue;
    });

    const noots_Analytes_JsonString = JSON.stringify(combinedAnalytes);
    if (noots_Analytes_JsonString.length) {
      if (angular.isDefined(this.obsNutrient)) {
        this.obsNutrient.Noots.Analytes = noots_Analytes_JsonString;
      }
    }

    this.onChange();
  }

  public showDeleteDialog(name: string) {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('NUTR.ANA.EXCLUDE_ANALYTE'))
      .textContent(this._languageService.instant('NUTR.ANA.EXCLUDE_CONFIRM', { n: name }))
      .ariaLabel(this._languageService.instant('NUTR.ANA.EXCLUDE_ANALYTE'))
      .ok(this._languageService.instant('COMMON.EXCLUDE'))
      .cancel(this._languageService.instant('COMMON.CANCEL'));

    const cancelledCallback = () => {};

    const confirmCallback = () => {
      const checkAnalyte = (analyte: Analyte) => {
        return analyte.Name === name;
      };

      // exclude analyte
      const foundAnalyteIndex = this.obsNutrient.Noots.JSONDisplayAnalytes.findIndex(checkAnalyte);
      if (foundAnalyteIndex > -1) {
        this.obsNutrient.Noots.JSONDisplayAnalytes.splice(foundAnalyteIndex, 1);
      }
    };

    const finalCallback = () => {
      this.updateAnalytes();

      this.entityManager.saveChanges([this.obsNutrient]);
    };

    this._mdDialog.show(confirm).then(confirmCallback, cancelledCallback).finally(finalCallback);
  }
}

angular.module('app.nutrients').component('swanNonCalculatedAnalyteForm', new NonCalculatedAnalyteFormComponent());
