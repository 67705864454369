import { breezeClass } from '@common/models/baseClass';

export class AuthClient extends breezeClass implements breeze.Entity {
  Id: string;
  Secret: string;
  Name: string;
  ApplicationType: string;
  Active: boolean;
  RefreshTokenLifeTime: number;
  AllowedOrigin: string;
}
