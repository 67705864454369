import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';
import { AuthAccountClass } from './AuthAccountClass';
import { Contact } from './Contact';
import { SysMessageLog } from './SysMessageLog';
import { WorkAgent } from './WorkAgent';

export class AuthAccount extends breezeClass implements breeze.Entity {
  AuthAccountId: number;
  AuthAccountClassId: number;
  CompanyName: string;
  ContactId: number;
  DefaultTZStandardName: string;
  DefaultReportingUnits: string;
  DayOffset: Date;
  IrrigationLocalStartTime: Date;
  IrrigationDayOffset: number;
  Config: string;

  BusinessName: string;
  BusinessAddress: string;
  Country: string;
  Status: string;
  WaterModule: boolean;
  NutriantModule: boolean;
  PrimaryContactName: string;
  PrimaryContactPhone: string;
  PrimaryContactEmail: string;
  SecondaryContactName: string;
  SecondaryContactPhone: string;
  SecondaryContactEmail: string;

  Assets: Asset[];
  AuthAccountClass: AuthAccountClass;
  Contact: Contact;
  SysMessageLogs: SysMessageLog[];
  WorkAgent: WorkAgent;
}
