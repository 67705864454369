import { uomUnit } from '@services/unit-of-measure.service';
import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { DataInput } from './DataInput';
import { WorkAgent } from './WorkAgent';

export class SiteAsset extends breezeClass implements breeze.Entity {
  unit: uomUnit; //used with tank mixes to store weight/fertVolume unit as appropriate
  Id: number;
  ParentAssetId: number;
  ChildAssetId: number;
  DataInputId: number;
  Coefficient: number;
  Priority: number;
  Factor: number;
  EffectiveFrom: Date;
  EffectiveTo: Date;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  AuthUser: AuthUser;
  ChildAsset: Asset;
  DataInput: DataInput;
  ParentAsset: Asset;
  WorkAgent: WorkAgent;
}
