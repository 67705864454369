import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';
import { AuthAccount } from './AuthAccount';
import { AuthUser } from './AuthUser';
import { WorkAgent } from './WorkAgent';

export class SysMessageLog extends breezeClass implements breeze.Entity {
  Id: number;
  Level: number;
  DateTime: Date;
  Comment: string;
  AssetId: number;
  AuthAccountId: number;
  ExtData: string;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Asset: Asset;
  AuthAccount: AuthAccount;
  AuthUser: AuthUser;
  WorkAgent: WorkAgent;
}
