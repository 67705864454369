// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#run-data-capture md-input-container {
  margin: 0 10px;
  padding: 0;
}
#run-data-capture md-input-container .md-errors-spacer {
  min-height: 0px;
}
#run-data-capture md-input-container .md-input {
  width: 80px;
  text-align: center;
}
#run-data-capture md-input-container md-datepicker .md-datepicker-input {
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/_components/common/run-data-capture.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,UAAA;AAAJ;AACI;EACE,eAAA;AACN;AACI;EACE,WAAA;EACA,kBAAA;AACN;AAEM;EACE,YAAA;AAAR","sourcesContent":["#run-data-capture {\r\n  md-input-container {\r\n    margin: 0 10px;\r\n    padding: 0;\r\n    .md-errors-spacer {\r\n      min-height: 0px;\r\n    }\r\n    .md-input {\r\n      width: 80px;\r\n      text-align: center;\r\n    }\r\n    md-datepicker {\r\n      .md-datepicker-input {\r\n        width: 200px;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
