import * as moment from 'moment';
import { breezeClass } from '@common/models/baseClass';
import { LanguageService } from '@services/language.service';
import { uomUnit } from '@services/unit-of-measure.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { Asset } from './Asset';
import { Property } from './Property';
import { SWANConstants } from '@common/SWANConstants';

export enum PropertyType {
  String = 'STRING',
  Date = 'DATE',
  Numeric = 'NUMERIC',
  Dropdown = 'DROPDOWN',
  Time = 'TIME',
  TimeRange = 'TIME_RANGE',
  IntegerSeries = 'INTEGER_SERIES',
}

export class PropertyValue extends breezeClass implements breeze.Entity {
  Id: number;
  AssetId: number;
  Asset: Asset;
  PropertyId: number;
  Property: Property;
  Value: string;

  public regExp: RegExp;
  public validationType = '';
  public param: {};
  public uomUnit: uomUnit;

  private basicInputs = [
    PropertyType.String,
    PropertyType.Numeric,
    PropertyType.Time,
    PropertyType.IntegerSeries,
    PropertyType.TimeRange,
  ];

  private _languageService: LanguageService;
  private _fuseProp: fuse.property;
  private _multiSelectValues = null;
  private _localDate: Date;
  private _timeStart: string;
  private _timeEnd: string;
  private _displayValue1: string; // Used where .Value contains multiple values (time range, multiple text input, etc)
  private _separator: string = ',';

  constructor() {
    super();
  }

  public get fuseProp() {
    return this._fuseProp;
  }

  public set fuseProp(prop: fuse.property) {
    this._fuseProp = prop;

    if (prop.multiSelect == 1) {
      prop.multiSelect = 0;
    }

    if (!this.isBasicInput) {
      return;
    }

    const configureRegexByType = (): void => {
      this.regExp = RegExp(''); // By default match anything as valid.

      const setNumberValidation = (): void => {
        this.regExp = SWANConstants.RegexDecimalNumber;
        this.validationType = 'NUMBER';
      };

      switch (this.fuseProp.type) {
        case PropertyType.TimeRange:
        case PropertyType.Time:
          this.regExp = SWANConstants.RegexTimeInput;
          this.validationType = 'HHMM';

          break;
        case PropertyType.Numeric:
          const dp = this.fuseProp.decimalPlaces;

          if (this.fuseProp.unitBaseClassId) {
            // WWhen the property value is the subject of conversion to the base UoM value, we apply regular number validation.
            // This is because converted base UoM values commonly end up having more than 2 decimal places, and we must preserve this precision for reverse conversion.
            setNumberValidation();

            break;
          }

          switch (dp) {
            case null:
              // unlimited decimals
              setNumberValidation();

              break;
            case 0:
              // integer
              this.regExp = SWANConstants.RegexIntegerNumber;
              this.validationType = 'INTEGER';

              break;
            default:
              // up to n decimals
              this.regExp = SWANConstants.RegexDecimalNumberWithXDecimals(dp);
              this.validationType = 'DP';
              this.param = { dp: dp };
          }

          break;
      }
    }

    // Setup basic input regex validation.
    if (this.fuseProp.regEx) {
      // Regex supplied by DB configuration.
      this.regExp = RegExp(this.fuseProp.regEx);
    } else {
      configureRegexByType();
    }
  }

  public get labelTranslation(): string | null {
    const translation = this.getTranslation('PROPERTIES.LABEL.');

    return translation ?? this.fuseProp.label; // This is a fallback to a 'label' column value in '[metadata].[Property]' table.
  }

  public get helpTranslation(): string | null {
    const translation = this.getTranslation('PROPERTIES.HELP.');

    return translation ?? this.fuseProp.helpText; // This is a fallback to a 'helpText' column value in '[metadata].[Property]' table.
  }

  public get multiSelectValues(): string[] {
    if (this._multiSelectValues || !this.Value) {
      return this._multiSelectValues;
    } else {
      this._multiSelectValues = this.Value.split('|');

      return this._multiSelectValues;
    }
  }

  public set multiSelectValues(vals: string[]) {
    if (vals != this._multiSelectValues) {
      this._multiSelectValues = vals;
      this.Value = vals?.join('|');
    }
  }

  public get localDate(): Date {
    if (this._localDate == null && this.Value != null) {
      this._localDate = moment(this.Value, 'YYYY-MM-DD').toDate();
    }

    return this._localDate;
  }

  public set localDate(date: Date) {
    this._localDate = date;
    this.Value = moment(date).format('YYYY-MM-DD');
  }

  public get timeStart(): string {
    this._timeStart || this.setStartEndTimes();

    return this._timeStart;
  }

  public get timeEnd(): string {
    this._timeEnd || this.setStartEndTimes();

    return this._timeEnd;
  }

  public set timeStart(time: string) {
    if (this._timeStart != time) {
      this._timeStart = time;
      this.setTimeRange();
    }
  }

  public set timeEnd(time: string) {
    if (this._timeEnd != time) {
      this._timeEnd = time;
      this.setTimeRange();
    }
  }

  public get separator(): string {
    if (!this._separator && this.Value) {
      this._separator = this.Value[0]; // first character is separator
      this._displayValue1 = this.Value.slice(1);
    }

    return this._separator;
  }

  public set separator(sep) {
    this._separator = sep;
    this.Value = sep + this._displayValue1;
  }

  public get displayValue1(): string {
    if (this.isTimeRange) {
      return this.timeStart;
    }

    if (this.isTextMulti) {
      if (!this._displayValue1 && this.Value) {
        this._separator = this.Value[0]; // first character is separator
        this._displayValue1 = this.Value.slice(1);
      }

      return this._displayValue1;
    }

    // Could include unit conversion code here too, but existing directives handle that well already.
    return this.Value;
  }

  public set displayValue1(val: string) {
    if (this.isTimeRange) {
      this.timeStart = val;

      return;
    }

    if (this.isTextMulti) {
      this._displayValue1 = val;
      this.Value = this._separator + this._displayValue1;

      return;
    }

    this.Value = val;
  }

  public get isBasicInput(): boolean {
    if (!this.fuseProp) {
      return false;
    }

    return this.basicInputs.includes(this.fuseProp.type as PropertyType);
  }

  public get isDropDown(): boolean {
    return this.fuseProp?.type == PropertyType.Dropdown;
  }

  public get isMultiSelect(): boolean {
    return this.isDropDown && !!this.fuseProp?.multiSelect;
  }

  public get isDate(): boolean {
    return this.fuseProp?.type == PropertyType.Date;
  }

  public get isTimeRange(): boolean {
    return this.fuseProp?.type == PropertyType.TimeRange;
  }

  public get isTextMulti(): boolean {
    return this.isBasicInput && !!this.fuseProp?.multiSelect;
  }

  public get hasUnit(): boolean {
    return !!this.fuseProp?.unitBaseClass;
  }

  public get isHidden(): boolean {
    return !!this.fuseProp?.hidden;
  }

  public get swanEditOnly(): boolean {
    return !!this.fuseProp?.swanEditOnly;
  }

  public setProperties(prop: fuse.property, languageService: LanguageService, unitOfMeasureService: UnitOfMeasureService = null) {
    this._languageService = languageService;
    this.fuseProp = prop;

    if (this.hasUnit && unitOfMeasureService) {
      this.uomUnit = unitOfMeasureService.getUnits(prop.unitBaseClass, prop.scaleId);
    }
  }

  /** Returns null if valid, error message string if invalid */
  public validate(): string {
    if (this.isTextMulti) {
      if (!this._displayValue1) {
        if (this.fuseProp.required) {
          return this.getValidationMessage('REQUIRED');
        }

        return null;
      }

      const parts = this._displayValue1
        .split(this._separator)
        .map((p) => p.trim())
        .filter((p) => !!p);

      if (this.fuseProp.multiSelect > 0 && parts.length > this.fuseProp.multiSelect) {
        return this.getValidationMessage('OVER_LIMIT', { n: this.fuseProp.multiSelect });
      }

      const err = parts.filter((part) => !this.regExp.exec(part));

      if (err.length) {
        return this.getInvalidValidationMessage(err.join(this.separator));
      }

      return null;
    }

    // Unlike other textbox inputs, Time Range validates both simultaneously and just prints one error if either incorrect ('required' status also handled as a format error)
    if (this.isTimeRange) {
      // If not required and both boxes empty, input is fine
      if (!this.fuseProp.required && !this.timeStart && !this.timeEnd) {
        return null;
      }

      // If input is required and either value missing, or if only one value has been entered, prompt user to fill both boxes
      if ((this.fuseProp.required && !(this._timeStart && this._timeEnd)) || !this._timeStart != !this._timeEnd) {
        return this.getValidationMessage('BOTH_TIMES');
      }

      const error = !this.regExp.exec(this._timeStart) || !this.regExp.exec(this._timeEnd);

      if (error) {
        return this.validationType; // use default format error message
      }

      return null;
    }

    if (this.fuseProp.required && !this.Value) {
      return this.getValidationMessage('REQUIRED');
    }

    if (!this.isBasicInput || !this.Value) {
      return null; // if not required, null is fine
    }

    if (this.fuseProp.type == PropertyType.IntegerSeries) {
      return this.checkIntSeries(this.Value);
    }

    // Check for numeric or DB-specified regex match
    const error = !this.regExp.exec(this.Value);

    if (error) {
      if (this.validationType != '') {
        return this.getValidationMessage(this.validationType, this.param);
      }

      return this.getInvalidValidationMessage(this.Value);
    }

    // Numeric tests not covered by regex
    if (this.fuseProp.type == PropertyType.Numeric) {
      const num = Number(this.Value);

      if (num != null && !isNaN(num) && (this.fuseProp.rangeFrom || this.fuseProp.rangeTo)) {
        let max = Number(this.fuseProp.rangeTo);
        let min = Number(this.fuseProp.rangeFrom);

        if (this.fuseProp.rangeFrom && num < min) {
          if (this.hasUnit && this.uomUnit) {
            min = this.uomUnit.fromBaseRounded(min);
          }

          return this.getValidationMessage('MIN', { min: min });
        }

        if (this.fuseProp.rangeTo && num > max) {
          if (this.hasUnit) {
            max = this.uomUnit.fromBaseRounded(max);
          }

          return this.getValidationMessage('MAX', { max: max });
        }
      }
    }

    return null;
  }

  private setTimeRange() {
     this.Value = this._timeStart && this._timeEnd ? `${this._timeStart}-${this._timeEnd}` : null;
  }

  private setStartEndTimes() {
    if (!this.isTimeRange || !this.Value) {
      return;
    }

    const times = this.Value.split('-');

    // If format invalid, log error and set everything to null
    if (times.length != 2 || times.some((time) => !this.regExp.exec(time))) {
      console.log(`Time format error: ${this.Value}`);

      this.Value = null;
      this._timeEnd = this._timeStart = null;
    }

    this._timeStart = times[0];
    this._timeEnd = times[1];
  }

  /**
   * Special checks for Integer Series
   * Returns error message if invalid or null if valid
   */
  private checkIntSeries(series: string) {
    if (!series) {
      return null; // if a property is required, it will be handled by required textbox attribute.
    }

    const parts = series.split(',').map((p) => p.trim());
    const allValues = {};

    for (let i = 0; i < parts.length; i++) {
      const p = parts[i];

      if (p == '' && i == parts.length - 1) {
        return null; // Trailing comma, don't worry about it.
      }

      const val = Number(p);

      if (SWANConstants.RegexIntegerPositiveNumber.exec(p)) {
        // Single digit: 1,4,15,etc

        if (allValues[val] !== undefined) {
          return this.getValidationMessage('SERIES_DUPE', { val: val });
        }

        allValues[val] = true;
      } else {
        const range = SWANConstants.RegexIntegerPositiveRange.exec(p);

        if (range) {
          // Integer range: 1-3, 5-18, etc
          const rangeStart = Number(range[1]);
          const rangeEnd = Number(range[2]);

          if (rangeEnd <= rangeStart) {
            return this.getValidationMessage('SERIES_RANGE', { val: p });
          }

          for (let j = rangeStart; j <= rangeEnd; j++) {
            if (allValues[j] !== undefined) {
              return this.getValidationMessage('SERIES_DUPE', { val: val });
            }

            allValues[j] = true;
          }
        } else {
          return this.getValidationMessage('SERIES_FORMAT', { val: p });
        }
      }
    }

    return null;
  }

  private getTranslation(translationPrefix: string): string | null {
    const isHelpTranslation = translationPrefix === 'PROPERTIES.HELP.';
    const hasTranslationData = this.fuseProp.translationKey?.split('|');
    // NOTE: When ':NO_HELP' marker text is specified then the translation of the 'help' key is avoided.
    const hasHelpTranslation = isHelpTranslation && this.fuseProp.translationKey?.includes(':NO_HELP');

    if (!hasTranslationData || hasHelpTranslation) {
      return null;
    }

    const translationKeySuffix = hasTranslationData[0];
    const translationKeyParams = hasTranslationData[1]?.split(',').reduce((acc, curr, idx) => {
      acc[`v${idx}`] = curr;

      return acc;
    }, {});

    return this._languageService?.instant(`${translationPrefix}${translationKeySuffix}`, translationKeyParams);
  }

  private getInvalidValidationMessage(value: string): string {
    const params = { values: value };

    if (this.helpTranslation) {
      return this.getValidationMessage('INVALID_HELP', {
        ...params,
        help: this.helpTranslation,
      });
    } else {
      return this.getValidationMessage('INVALID', params);
    }
  }

  private getValidationMessage(validationType, params = null): string {
    return this._languageService.instant(`COMMON.ERRORS.${validationType}`, params);
  }
}
