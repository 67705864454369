import * as angular from 'angular';
import { DayNumberService } from '@services/day-number.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

class RunDataCaptureComponent implements angular.IComponentOptions {
  bindings = {
    optionChange: '&',
  };
  controller = RunDataCaptureController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/common/run-data-capture.html';
}

class RunDataCaptureController extends BaseController {
  private optionChange: Function;
  public isIntervalMode = true;
  public runFromDate: Date;
  public runEndDate: Date;
  public runDays = 2;
  public minDate: Date;
  public maxDate: Date;
  public maxRunDays: number;
  public isSuperUser = false;

  constructor(
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    const accountAdjustedToday = DayNumberService.convertBrowserDateTimeToLocaleDate();

    this.runEndDate = accountAdjustedToday.clone().addDays(-1);
    this.runFromDate = accountAdjustedToday.clone().addDays(-2);
    this.maxDate = accountAdjustedToday.clone().addDays(-1);
    const isSuperUser = PermissionService.isSuperUser;
    if (!isSuperUser) {
      this.minDate = this.runEndDate.clone().addYears(-1);
      this.maxRunDays = 365;
    }
  }

  $onInit() {
    // need trige the event after initialised
    this.optionChanged();
  }

  public optionChanged(): void {
    const option = {
      isIntervalMode: this.isIntervalMode,
      fromDateYMD: this.runFromDate.toString('yyyy-MM-dd'),
      endDateYMD: this.runEndDate.toString('yyyy-MM-dd'),
      runDays: this.runDays,
    } as fuse.dataCaptureDto;
    this.optionChange({ dataCaptureOption: option });
  }
}

angular.module('fuse').component('runDataCapture', new RunDataCaptureComponent());
