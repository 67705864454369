import { breezeClass } from '@common/models/baseClass';

export class MarketRegions extends breezeClass implements breeze.Entity {
  Id: number;
  Shape: any;
  Country: string;
  Province: string;
  Continent: string;
  Region: string;
  ISO2: string;
  ISO3: string;
  Cent_lng: number;
  Cent_lat: number;
}
