import * as moment from 'moment';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { WorkAgent } from './WorkAgent';
import { breezeClass } from '@common/models/baseClass';

export class SiteSettingsSoil extends breezeClass implements breeze.Entity {
  /// <code> Place custom code between <code> tags
  private _localDate: Date;
  private _effectiveYMD: string;

  get localDate(): Date {
    if (this._localDate == null || this._effectiveYMD != this.EffectiveFromYMD) {
      if (this.EffectiveFromYMD != null) {
        this._localDate = moment(this.EffectiveFromYMD, 'YYYY-MM-DD').toDate();
        this._effectiveYMD = this.EffectiveFromYMD;
      }
    }
    return this._localDate;
  }

  set localDate(date: Date) {
    this._localDate = date;
    this.EffectiveFromYMD = moment(date).format('YYYY-MM-DD');
    this._effectiveYMD = this.EffectiveFromYMD;
  }
  Id: number;
  DayNumber: number;
  WiltingPoint_pct: number;
  FieldCapacity_pct: number;
  Saturation_pct: number;
  WorkingSoilDepthTop_mm: number;
  WorkingSoilDepthBottom_mm: number;
  MinPenetratingRainfall_24Hours_mm: number;
  MaxPenetratingRainfall_24Hours_mm: number;
  DrainageCoefficient: number;
  SoilMoistureLowerTarget_percent: number;
  SoilMoistureUpperTarget_percent: number;
  AssetId: number;
  Comment: string;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Asset: Asset;
  AuthUser: AuthUser;
  WorkAgent: WorkAgent;
  EffectiveFromYMD: string;
}
