import { breezeClass } from '@common/models/baseClass';

export class AuthRefreshToken extends breezeClass implements breeze.Entity {
  Id: string;
  Subject: string;
  ClientId: string;
  IssuedUtc: Date;
  ExpiresUtc: Date;
  ProtectedTicket: string;
}
