import * as angular from 'angular';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';

angular.module('fuse').directive('displayUnits', (UnitOfMeasureService: UnitOfMeasureService) => {
  return {
    require: 'ngModel',
    scope: {
      displayUnits: '<',
    },
    link: (scope, element, attrs, ngModel: any) => {
      if (scope['unit'] != null && !scope['unit'].hasOwnProperty('fromBaseRounded')) {
        console.error('displayUnits: unit invalid');
      }

      const unit = scope['displayUnits'] as uomUnit;
      const unitDecimal = attrs.unitDecimal || unit.decimalPlaces;

      // Convert to base UoM.
      ngModel.$parsers.unshift((viewValue) => {
        const isValidNumber = RegExp('^-*\\d+(\\.\\d+)?$').test(viewValue);

        if (!isValidNumber) {
          // Prevent conversion to base UoM as invalid input detected.
          return viewValue;
        }

        return unit.toBaseWithNulls(viewValue);
      });

      // Convert from base UoM.
      ngModel.$formatters.unshift((modelValue) => {
        return unit.fromBaseRounded(modelValue, unitDecimal);
      });

      UnitOfMeasureService.setListeners(element, unitDecimal);
    },
  };
});
