import * as angular from 'angular';

/** Standard dropdown selector for Active/Archived/Suspended status, instantiate as <status-dropdown status="model">
 *  Can be instantiated with <status-dropdown active-suspended="true"... to exclude 'Archived' option, or active-archived="true" to exclude 'Suspended' option
 */
class StatusDropdownComponent implements angular.IComponentOptions {
  bindings = {
    activeArchived: '<',
    activeSuspended: '<',
    isReadOnly: '<',
    isVisibleLabel: '<',
    status: '=',
  };
  controller = StatusDropdownController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/status-dropdown.html';
}

class StatusDropdownController implements angular.IController {
  public statuses = ['Active', 'Suspended', 'Archived']; // Can include 'Active', 'Archived' and/or 'Suspended'

  public activeArchived: boolean;
  public activeSuspended: boolean;
  public isReadOnly: boolean;
  public isVisibleLabel = true;
  public status: string;

  constructor() {}

  $onInit() {
    this.isVisibleLabel = this.isVisibleLabel ?? true;

    this.status = this.status || 'Active';

    if (this.activeArchived) {
      this.statuses = ['Active', 'Archived'];
    }

    if (this.activeSuspended) {
      this.statuses = ['Active', 'Suspended'];
    }
  }
}

angular.module('fuse').component('statusDropdown', new StatusDropdownComponent());
