import * as angular from 'angular';

angular.module('fuse').directive('calendarReadonly', () => {
  return {
    restrict: 'A',
    link: (scope, element) => {
      element.find('input')[0].setAttribute('readonly', 'true');

      element.find('input').on('click', () => {
        element.find('button')[0].click();
      });
    },
  };
});
