import * as angular from 'angular';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { SysMessageLog } from 'src/app/_DBContext/SysMessageLog';
import { SWANConstants } from '@common/SWANConstants';
import { IDropListItem } from '@common/models/interfaces';
import { LanguageService } from '@services/language.service';
import { DayNumberService } from '@services/day-number.service';
import { ApiService } from '@services/api.service';
import { StringUtils } from '@indicina/swan-shared/utils/StringUtils';

class PagedLogs implements angular.IComponentOptions {
  bindings = {
    downloadPermission: '=',
  };
  controller = PagingController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/paged-logs.html';
}

class PagingController {
  public assetId: number;
  public browserTimezoneLabel: string;
  public minDate = SWANConstants.MinDate;
  public logFromDate: Date;
  public logToDate: Date;
  public logLevels: IDropListItem[];
  public selectedLogLevel: number;
  public currentPage = 1;

  public pagedLogsData: any;
  public arrLogsDataCSV: any;
  public arrLogsDataCSVHeader: any;

  public hasLogsDownloadPermission: boolean;
  public isGoToPrevDisabled: boolean;
  public isGoToNextDisabled: boolean;

  private _apiService: ApiService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  private _logsData;
  private _pageSize = 20;
  private _totalRecords = 0;
  private _maxPage: number = 1;

  constructor(
    ApiService: ApiService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
  ) {
    this._apiService = ApiService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;

    this.browserTimezoneLabel = DateUtils.getBrowserTimezoneLabel();
    this.logToDate = this._dayNumberService.endOfToday();
    this.logFromDate = this._dayNumberService.daysAgo(30);
    this.logLevels = SWANConstants.LogLevels;
    this.selectedLogLevel = 3;

    const context = LocalStorageUtils.contextData;
    this.assetId = context.assetId || context.siteId;
    this.arrLogsDataCSVHeader = ['COMMON.DATE', 'COMMON.LEVEL', 'COMMON.COMMENT'].map((h) => this._languageService.instant(h));
  }

  $onInit() {
    this.loadLogs();
  }

  public loadLogs(): void {
    const translate = (input: string): string => {
      const translateFunc = this._languageService.instant.bind(this._languageService);

      if (StringUtils.isJson(input)) {
        return translateFunc(input);
      }

      if (StringUtils.isLikeTranslationKey(input)) {
        return translateFunc(input);
      }

      return input;
    };

    const sysMessageLogsCallback = (data: any[]): void => {
      this._logsData = data.map((x) => ({
        ...x,
        Comment: translate(x.Comment),
      }));
      this._totalRecords = this._logsData.length;
      this._maxPage = Math.ceil(this._totalRecords / this._pageSize);

      this.arrLogsDataCSV = this._logsData.map((x) => ({
        date: x.DateTime.toString(),
        level: x.Level.toString(),
        comment: x.Comment,
      })) as SysMessageLog[];

      this.setPageData();
      this.setPagingButtons();
    };

    const params = { AssetId: this.assetId, FromDate: this.logFromDate, ToDate: this.logToDate, Level: this.selectedLogLevel };

    this._apiService.getGeneric('data/SysMessageLogAsset', params, sysMessageLogsCallback);
  }

  public handleChangeLogFilters() {
    this.currentPage = 1;
    this.loadLogs();
  }

  public goToFirst() {
    this.currentPage = 1;
    this.setPageData();
    this.setPagingButtons();
  }

  public goToPrev() {
    this.currentPage--;
    this.setPageData();
    this.setPagingButtons();
  }

  public goToNext() {
    this.currentPage++;
    this.setPagingButtons();
    this.setPageData();
  }

  public goToLast() {
    this.currentPage = this._maxPage;
    this.setPagingButtons();
    this.setPageData();
  }

  private setPageData() {
    const start = (this.currentPage - 1) * this._pageSize;
    const end = Math.min(start + this._pageSize, this._logsData.length);

    this.pagedLogsData = this._logsData.slice(start, end);
  }

  private setPagingButtons() {
    this.isGoToPrevDisabled = this.currentPage === 1;
    this.isGoToNextDisabled = this.currentPage === this._maxPage || this._totalRecords == 0;
  }
}

angular.module('fuse').component('pagedLogs', new PagedLogs());
