/** Works with EntityList to filter tables by Active/Archived/Suspended status. For more complex filtering, can also pass a TableControl object. */

import * as angular from 'angular';
import { EntityList } from '@common/EntityList';
import { FilterService } from '@services/filter.service';

export class StatusFilter implements angular.IComponentOptions {
  bindings = {
    statuses: '<',
    entityList: '=',
    saveTo: '=',
  };
  controller = StatusFilterController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/status-filter.html';
}

export class StatusFilterController {
  public entityList: EntityList<any>;
  public statuses: string[]; // Can be 'Active', 'Archived', 'Suspended'.
  public saveTo: FilterService;

  public includeArchived: boolean;
  public includeSuspended: boolean;

  constructor() {}

  $onInit() {
    this.includeArchived = this.statuses?.includes('Archived');
    this.includeSuspended = this.statuses?.includes('Suspended');
  }

  public isEnabled(status: string): boolean {
    if (this.entityList?.statusFilters) {
      return this.entityList.statusFilters[status];
    }
  }

  public toggleButton(status: string): void {
    this.entityList?.toggleButton(status);

    if (this.saveTo) {
      this.saveTo.statusFilters = this.entityList.statusFilters;
    }
  }
}

angular.module('fuse').component('statusFilter', new StatusFilter());
