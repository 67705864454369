import { breezeClass } from '@common/models/baseClass';
import { AuthAccount } from './AuthAccount';
import { AuthUser } from './AuthUser';
import { CropCoefficient } from './CropCoefficient';
import { CropTolerance } from './CropTolerance';
import { Site } from './Site';
import { WorkAgent } from './WorkAgent';

export class Crop extends breezeClass implements breeze.Entity {
  /// <code> Place custom code between <code> tags

  /// </code>

  // Generated code. Do not place code below this line.
  Id: number;
  AuthAccountId: number;
  Name: string;
  Notes: string;
  TolWet: number;
  TolDry: number;
  TolCold: number;
  TolHeat: number;
  IsForWaterBudget?: boolean;
  BudgetStartDate: Date;
  BudgetEndDate: Date;
  JanuaryDailyKc: number;
  FebruaryDailyKc: number;
  MarchDailyKc: number;
  AprilDailyKc: number;
  MayDailyKc: number;
  JuneDailyKc: number;
  JulyDailyKc: number;
  AugustDailyKc: number;
  SeptemberDailyKc: number;
  OctoberDailyKc: number;
  NovemberDailyKc: number;
  DecemberDailyKc: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  AuthAccount: AuthAccount;
  AuthUser: AuthUser;
  CropClass: CropTolerance;
  CropCoefficients: CropCoefficient[];
  Sites: Site[];
  WorkAgent: WorkAgent;
}
