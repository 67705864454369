import { breezeClass } from '@common/models/baseClass';
import { AuthUser } from './AuthUser';
import { ObsWeatherRollup } from './ObsWeatherRollup';
import { WorkAgent } from './WorkAgent';

export class SiteDailySummary extends breezeClass implements breeze.Entity {
  Id: number;
  SoilMoisture: number;
  IrrigationBudget: number;
  IrrigationPlanned: number;
  Irrigation: number;
  IrrigationLocked: boolean;
  IrrigationStatus: number;
  Drainage: number;
  Runoff: number;
  AssetId: number;
  dayNumber: number;
  dayDisplayYMD: string;
  QualityFactor: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  AuthUser: AuthUser;
  ObsWeatherRollup: ObsWeatherRollup;
  WorkAgent: WorkAgent;
  FertigationPlanned: number;
}
