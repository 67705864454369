import * as angular from 'angular';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { NutrientDashboard } from '@common/models/interfaces';
import { unitSizes } from '@common/enums';

class NutrientsSummaryTableComponent implements angular.IComponentOptions {
  bindings = {
    nutrients: '<',
  };
  controller = NutrientsSummaryTableController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/nutrients-summary-table.html';
}

interface INutrientElement {
  element: string;
  applied: number;
  planned: number;
}

class NutrientsSummaryTableController {
  private nutrients: NutrientDashboard;
  public displayData: INutrientElement[];
  public weightAreaNormalUnit: string;

  constructor(UnitOfMeasureService: UnitOfMeasureService) {
    this.weightAreaNormalUnit = UnitOfMeasureService.getUnitLabel('Weight/Area', unitSizes.normal);
  }

  $onChanges(changes) {
    if (changes.nutrients?.currentValue) {
      this.displayData = this.nutrients.DisplayData.map((nutrient) => {
        return {
          element: nutrient.element,
          applied: nutrient.applied,
          planned: nutrient.planned,
        } as INutrientElement;
      });
    }
  }
}

angular.module('fuse').component('nutrientsSummaryTable', new NutrientsSummaryTableComponent());
