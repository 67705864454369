import * as angular from 'angular';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { AmGraphDataValues } from '@common/enums';
import { CalculatedResults } from '@common/nutrients.interface';
import { FertiliserService } from '@services/nutrients/fertiliser.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { uomUnit } from '@services/unit-of-measure.service';
import { NutrientService } from '@services/nutrients/nutrient.service';
import { SamplePointService } from '@services/nutrients/sample-point.service';
import { BaseController } from 'src/app/base.controller';
import { ObsNutrients } from 'src/app/_DBContext/ObsNutrients';
import { Analyte } from './analyte-form.component';

class SWANNutrientsComponent implements angular.IComponentOptions {
  bindings = {
    parentPage: '@',
    assetId: '<',
    defaultUnit: '<',
    fertiliserConcentrationMode: '<',
    fertiliserSpecificGravity: '<',
    calculatedResults: '=',
    rejectChanges: '<',
    reloadChanges: '<',
    obsNutrient: '=',
    uomUnit: '<',
    nitrogenChanges: '<',
  };
  controller = NutrientsController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/nutrients/nutrients-form.component.html';
}

enum CALCULATION_TYPE {
  EDIT,
  INIT,
}

class NutrientsController extends BaseController {
  public units = 'mg/L';
  public nootNames;
  public elementOpts;
  public nutrientDecimalPlaces: Record<string, number | null> = {};

  public elements: string[];
  public nutrientSigns: string[] = [];

  public obsNutrient: ObsNutrients;
  public assetId: number;
  public defaultUnit: string;
  public fertiliserConcentrationMode: string;
  public fertiliserSpecificGravity: number;
  public calculatedResults: CalculatedResults;
  public nutrientDisplayValues: number[] = [];
  private nutrientDisplayValuesWeight: number[] = [];
  public showUnits: boolean;
  public parentPage: string;
  public uomUnit: uomUnit;

  private _fertiliserService: FertiliserService;
  private _languageService: LanguageService;
  private _nutrientService: NutrientService;
  private _samplePointService: SamplePointService;

  constructor(
    $scope: angular.IScope,
    FertiliserService: FertiliserService,
    LanguageService: LanguageService,
    NutrientService: NutrientService,
    PermissionService: PermissionService,
    SamplePointService: SamplePointService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.NutrientsAnalytesFull);

    this._fertiliserService = FertiliserService;
    this._languageService = LanguageService;
    this._nutrientService = NutrientService;
    this._samplePointService = SamplePointService;

    this.showUnits = true;
  }

  public theChartData = [];

  public elemKeys(noot) {
    return Object.keys(this.elementOpts[noot].opts);
  }

  public elementHasOptions(elem) {
    return !!this.elementOpts[elem];
  }

  public elemLabel(elem) {
    if (this.elementHasOptions(elem)) return this.elementOpts[elem].current;

    return elem;
  }

  $onInit() {
    // NOTE: The order of nutrient properties is relevant!
    this.nootNames = {
      P: this._languageService.instant('NUTR.CHEM.PHOSPHORUS'),
      K: this._languageService.instant('NUTR.CHEM.POTASSIUM'),
      S: this._languageService.instant('NUTR.CHEM.SULPHUR'),
      Ca: this._languageService.instant('NUTR.CHEM.CALCIUM'),
      Mg: this._languageService.instant('NUTR.CHEM.MAGNESIUM'),
      Na: this._languageService.instant('NUTR.CHEM.SODIUM'),
      Cl: this._languageService.instant('NUTR.CHEM.CHLORIDE'),
      Cu: this._languageService.instant('NUTR.CHEM.COPPER'),
      Fe: this._languageService.instant('NUTR.CHEM.IRON'),
      Mn: this._languageService.instant('NUTR.CHEM.MANGANESE'),
      Zn: this._languageService.instant('NUTR.CHEM.ZINC'),
      B: this._languageService.instant('NUTR.CHEM.BORON'),
      Mo: this._languageService.instant('NUTR.CHEM.MOLYBDENUM'),
    };

    this.elementOpts = {
      K: {
        current: 'K',
        opts: {
          K: { name: this._languageService.instant('NUTR.CHEM.POTASSIUM'), factor: 1 },
          K2O: { name: this._languageService.instant('NUTR.CHEM.POTASSIUM'), factor: 1.2047 },
        },
      },
      P: {
        current: 'P',
        opts: {
          P: { name: this._languageService.instant('NUTR.CHEM.PHOSPHORUS'), factor: 1 },
          P2O5: { name: this._languageService.instant('NUTR.CHEM.PHOSPHORUS'), factor: 2.2915 },
        },
      },
      S: {
        current: 'S',
        opts: {
          S: { name: this._languageService.instant('NUTR.CHEM.SULPHUR'), factor: 1 },
          SO4: { name: this._languageService.instant('NUTR.CHEM.SULPHATE'), factor: 3 },
        },
      },
    };

    this.elements = Object.keys(this.nootNames);
    this.nutrientDecimalPlaces = this._fertiliserService.getNutrientDecimalPaces(this.elements);

    this.elements.forEach((el) => {
      this.nutrientDisplayValues[el] = 0;
      this.nutrientDisplayValuesWeight[el] = 0;
    });

    if (this.parentPage == 'fertiliser') {
      this.showUnits = false;
    }
  }

  $onChanges(changes) {
    //Temp fix for Cancel changes where models are not binding directly to control
    if (changes.rejectChanges?.currentValue > 0) {
      this.updateDataAndChart(CALCULATION_TYPE.INIT);
    }

    if (changes.reloadChanges?.currentValue > 0) {
      for (const prop in this.nutrientSigns) {
        this.nutrientSigns[prop] = null;
      }

      if (!this.obsNutrient.Noots.Analytes) {
        this.obsNutrient.Noots.JSONAnalytes = [];
      } else {
        this.obsNutrient.Noots.JSONAnalytes = JSON.parse(this.obsNutrient.Noots.Analytes).filter((analyte: Analyte) => {
          return angular.isUndefined(analyte.CustomValue);
        });
      }

      this.updateDataAndChart(CALCULATION_TYPE.INIT);
    }

    if (
      angular.isDefined(this.obsNutrient) &&
      changes.fertiliserConcentrationMode &&
      !!changes.fertiliserConcentrationMode.currentValue
    ) {
      this.updateDataAndChart(CALCULATION_TYPE.EDIT);
    }

    if (angular.isDefined(this.obsNutrient) && this.defaultUnit == 'L' && changes.fertiliserSpecificGravity?.currentValue > 0) {
      this.updateDisplayValues();
      this.updateChart();
    }

    if (changes.nitrogenChanges?.currentValue > 0) {
      this.updateChart();
    }
  }

  public updateDataAndChart(calcType: CALCULATION_TYPE, isSpecificGravityChange: boolean = false) {
    this.convertDB(calcType, isSpecificGravityChange);
    this.updateChart();
  }

  private updateChart() {
    const totalN: number =
      (this.obsNutrient.Noots.NO3_N < 0 ? 0 : this.obsNutrient.Noots.NO3_N) +
      (this.obsNutrient.Noots.NH4_N < 0 ? 0 : this.obsNutrient.Noots.NH4_N);
    this.theChartData = [
      {
        name: this._languageService.instant('NUTR.CHEM.TOTAL_N'),
        value: totalN,
      },
    ];

    this.elements.forEach((elem) => {
      let value = this.nutrientDisplayValues[elem];

      if (this.nutrientSigns[elem] !== 'blank' && this.nutrientSigns[elem] !== undefined) {
        value = 0;
      }

      const element = this.elemLabel(elem);
      this.theChartData.push({ name: element, value: value });
    });

    AmCharts.makeChart('nutrient-summary-chart', {
      type: 'serial',
      theme: 'light',
      dataProvider: this.theChartData,
      backgroundColor: '#EFEFEF',
      backgroundAlpha: 0.8,
      valueAxes: [
        {
          gridColor: '#FFFFFF',
          gridAlpha: 0,
          labelsEnabled: false,
          axisAlpha: 0,
          minimum: 0,
        },
      ],
      gridAboveGraphs: true,
      startDuration: 0,
      graphs: [
        {
          fillAlphas: 0.8,
          fillColors: '#67b7dc',
          lineAlpha: 0.2,
          type: 'column',
          valueField: 'value',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            if (graphDataItem.values) {
              const val = (graphDataItem.values as AmGraphDataValues).value;
              const txt = graphDataItem.category;
              if (val) {
                return txt + ': ' + val.toFixed(2);
              }
            }
            return '';
          },
        },
      ],
      chartCursor: {
        categoryBalloonEnabled: false,
        cursorAlpha: 0,
        zoomable: false,
      },
      categoryField: 'name',
      categoryAxis: {
        gridPosition: 'start',
        gridAlpha: 0,
      },
    } as AmCharts.AmSerialChart);

    this.updateCalculatedResults();
  }

  private updateCalculatedResults() {
    if (this.parentPage != 'fertiliser') {
      this.calculatedResults = this._samplePointService.getCalculatedResults(this.obsNutrient.Noots);
    }
  }

  // Database stores '>' nutrients as negatives and N/A as -9999 - this func converts values back and forth for display
  private convertDB(calcType: CALCULATION_TYPE = CALCULATION_TYPE.EDIT, isSpecificGravityChange: boolean = false) {
    const excludeProps = ['NO3_N', 'NH4_N', 'Analytes', 'JSONAnalytes', 'JSONDisplayAnalytes'];

    // breeze.ComplexObject
    (this.obsNutrient.Noots as any).complexAspect.parentProperty.dataType.dataProperties.forEach((prop) => {
      if (excludeProps.indexOf(prop.name) === -1) {
        switch (calcType) {
          case CALCULATION_TYPE.EDIT:
            if (isSpecificGravityChange && this.fertiliserConcentrationMode == 'w/w' && this.fertiliserSpecificGravity > 0) {
              // Calculate w/v from w/w input
              this.nutrientDisplayValues[prop.name] = this.nutrientDisplayValuesWeight[prop.name] * this.fertiliserSpecificGravity;
            }

            this.obsNutrient.Noots[prop.name] = this._nutrientService.calculateDBValue(
              this.nutrientSigns[prop.name],
              this.nutrientDisplayValues[prop.name],
            );
            break;
          case CALCULATION_TYPE.INIT:
            this.nutrientDisplayValues[prop.name] = this._nutrientService.calculateDisplayValue(this.obsNutrient.Noots[prop.name]);
            this.nutrientSigns[prop.name] = this._nutrientService.getNutrientSign(this.obsNutrient.Noots[prop.name]);
            break;

          default:
            break;
        }
      }

      if (this.fertiliserSpecificGravity > 0) {
        // Calculate w/w values from w/v input or database
        this.nutrientDisplayValuesWeight[prop.name] = this.nutrientDisplayValues[prop.name] / this.fertiliserSpecificGravity;
      }
    });
  }

  public updateRecord(noot) {
    if (this.fertiliserSpecificGravity > 0) {
      if (this.fertiliserConcentrationMode == 'w/w') {
        // Calculate w/v from w/w input
        this.nutrientDisplayValues[noot] = this.nutrientDisplayValuesWeight[noot] * this.fertiliserSpecificGravity;
      } else if (this.fertiliserConcentrationMode == 'w/v') {
        // Calculate w/w from w/v input
        this.nutrientDisplayValuesWeight[noot] = this.nutrientDisplayValues[noot] / this.fertiliserSpecificGravity;
      }
    }

    let val = this.nutrientDisplayValues[noot];

    if (this.nutrientSigns[noot] == 'LessThan') {
      val = val * -1;
    }

    if (this.elementHasOptions(noot)) {
      const obj = this.elementOpts[noot];
      const currentType = obj.current;

      val = val / obj.opts[currentType].factor;
    }

    this.obsNutrient.Noots[noot] = val;

    this.updateChart();
  }

  public switchElement(noot) {
    if (this.elementHasOptions(noot)) {
      if (this.nutrientSigns[noot] == 'NotTested') {
        this.obsNutrient.Noots[noot] = -9999;
      } else if (this.obsNutrient.Noots[noot] == -9999) {
        this.obsNutrient.Noots[noot] = 0;
      }
      const obj = this.elementOpts[noot];
      const currentType = obj.current;
      const val = Math.abs(this.obsNutrient.Noots[noot]);
      const converted = val * obj.opts[currentType].factor;
      this.nutrientDisplayValues[noot] = converted;
      if (this.fertiliserSpecificGravity != 0) {
        this.nutrientDisplayValuesWeight[noot] = converted / this.fertiliserSpecificGravity;
      } else {
        this.nutrientDisplayValuesWeight[noot] = null;
      }
      this.updateChart();
    }
  }

  public switchSign(noot) {
    if (this.nutrientSigns[noot] == 'NotTested') {
      this.obsNutrient.Noots[noot] = -9999;
      this.nutrientDisplayValues[noot] = 0;
    } else if (this.nutrientSigns[noot] == 'LessThan') {
      if (this.obsNutrient.Noots[noot] == -9999) {
        this.obsNutrient.Noots[noot] = 0;
        this.nutrientDisplayValues[noot] = 0;
      } else {
        this.obsNutrient.Noots[noot] = -Math.abs(this.obsNutrient.Noots[noot]);
      }
    } else {
      if (this.obsNutrient.Noots[noot] == -9999) {
        this.obsNutrient.Noots[noot] = 0;
      } else {
        this.obsNutrient.Noots[noot] = Math.abs(this.obsNutrient.Noots[noot]);
      }
    }
    this.updateDisplayValues();
    this.updateChart();
  }

  private updateDisplayValues() {
    const excludeProps = ['NO3_N', 'NH4_N', 'Analytes', 'JSONAnalytes', 'JSONDisplayAnalytes'];
    // breeze.ComplexObject
    (this.obsNutrient.Noots as any).complexAspect.parentProperty.dataType.dataProperties.forEach((prop) => {
      if (excludeProps.indexOf(prop.name) === -1) {
        this.nutrientDisplayValuesWeight[prop.name] = this.obsNutrient.Noots[prop.name];
        if (this.nutrientDisplayValues[prop.name] != -9999) {
          if (this.fertiliserSpecificGravity > 0) {
            this.nutrientDisplayValuesWeight[prop.name] =
              Math.abs(this.nutrientDisplayValues[prop.name]) / this.fertiliserSpecificGravity;
          } else {
            this.nutrientDisplayValuesWeight[prop.name] = null;
          }
        }
      }
    });
  }
}

angular.module('app.nutrients').component('swanNutrients', new SWANNutrientsComponent());
