import { breezeClass } from '@common/models/baseClass';
import { IdentityUserClaim } from './IdentityUserClaim';
import { IdentityUserLogin } from './IdentityUserLogin';
import { IdentityUserRole } from './IdentityUserRole';

export class AuthUser extends breezeClass implements breeze.Entity {
  /// <code> Place custom code between <code> tags
  get FullName(): string {
    return `${this.FirstName} ${this.LastName}`;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  Id: string;
  FirstName: string;
  LastName: string;
  DefaultTimeZone: string;
  AgentId: number;
  CreatorAuthUserId: string;
  Email: string;
  EmailConfirmed: boolean;
  PasswordHash: string;
  SecurityStamp: string;
  PhoneNumber: string;
  PhoneNumberConfirmed: boolean;
  ContactMobileNumber: string;
  UserAccountStatus: string;
  TwoFactorEnabled: boolean;
  LockoutEndDateUtc: Date;
  LockoutEnabled: boolean;
  AccessFailedCount: number;
  UserName: string;
  Claims: IdentityUserClaim[];
  Logins: IdentityUserLogin[];
  Roles: IdentityUserRole[];
}
