import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';
import { AuthAccount } from './AuthAccount';
import { AuthUser } from './AuthUser';
import { WorkAgent } from './WorkAgent';

export class AssetClass extends breezeClass implements breeze.Entity {
  /// <code> Place custom code between <code> tags

  /// </code>

  // Generated code. Do not place code below this line.
  Id: number;
  Name: string;
  Mappable: string;
  IconURL: string;
  DataInputId: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Assets: Asset[];
  AuthAccount: AuthAccount;
  AuthUser: AuthUser;
  WorkAgent: WorkAgent;
}
