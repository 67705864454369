import { breezeClass } from '@common/models/baseClass';
import { Analyte } from '@common/nutrients.interface';

export class BlendedAnalytes {
  Noots: NutrientFields;
  NootDates: any;
  pH: number;
}

export class NutrientFields extends breezeClass implements breeze.Entity {
  /// <code> Place custom code between <code> tags
  JSONAnalytes: Analyte[];
  JSONDisplayAnalytes: Analyte[];
  /// </code>

  // Generated code. Do not place code below this line.
  NO3_N: number;
  NH4_N: number;
  P: number;
  K: number;
  Ca: number;
  Mg: number;
  S: number;
  Na: number;
  Cl: number;
  Cu: number;
  Fe: number;
  Mn: number;
  Zn: number;
  B: number;
  Mo: number;
  Analytes: string;
}
