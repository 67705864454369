import { AuthUser } from './AuthUser';
import { IdentityUserLogin } from './IdentityUserLogin';

export class AuthUserLogin extends IdentityUserLogin {
  AuthUserId: string;
  LoginProvider: string;
  ProviderKey: string;
  UserId: string;
  AuthUser: AuthUser;
}
