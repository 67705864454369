import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { WorkAgent } from './WorkAgent';

export class ValueRange extends breezeClass implements breeze.Entity {
  Id: number;
  AssetId: number;
  Property: string;
  Attribute: string;
  EffectiveFrom: Date;
  DayNumber?: number;
  ReadingLow: number;
  ReadingHigh: number;
  ErrorLevel: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Asset: Asset;
  AuthUser: AuthUser;
  WorkAgent: WorkAgent;
  DataInputId?: number;
  FromDayNumber?: number;
  ToDayNumber?: number;
}
