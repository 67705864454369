import { breezeClass } from '@common/models/baseClass';

export class Contact extends breezeClass implements breeze.Entity {
  Id: number;
  AuthAccountId: number;
  Shared: boolean;
  CompanyName: string;
  Class: string;
  Address: string;
  Suburb: string;
  Province: string;
  Country: string;
  PostCode: string;
  PersonName: string;
  PersonTitle: string;
  Email: string;
  Phone: string;
  Website: string;
  Notes: string;
}
