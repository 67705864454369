import { breezeClass } from '@common/models/baseClass';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { SiteSettingsCrop } from './SiteSettingsCrop';
import { SiteSettingsNutrients } from './SiteSettingsNutrients';
import { SiteSettingsSoil } from './SiteSettingsSoil';
import { SiteSettingsWater } from './SiteSettingsWater';
import { WorkAgent } from './WorkAgent';

export class Site extends breezeClass implements breeze.Entity {
  AssetId: number;
  Area: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  LastSMPredictionFromDay: number;
  LastSMPredictionInitiated: Date;
  Asset: Asset;
  AuthUser: AuthUser;
  CropSettings: SiteSettingsCrop[];
  NutrientSettings: SiteSettingsNutrients[];
  SoilSettings: SiteSettingsSoil[];
  WaterSettings: SiteSettingsWater[];
  WorkAgent: WorkAgent;
  IrrigationPattern: string;
  SmbPreference: string;
  Priority?: number;
  ApplicationMinimum?: number;
  ApplicationMaximum?: number;
  ApplicationIncrements?: number;
  Application100Pct?: number;
  ApplicationUpperThresholdFactor?: number;
  ApplicationUpperThreshold?: number;
  SMPAssetId?: number;
}
