import * as angular from 'angular';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';

interface MonthInfo {
  month: string;
  monthNumber: number;
  days: number;
  allowed: boolean;
}

interface DayInfo {
  display: string;
  value: number;
  allowed: boolean;
}

class DayMonthPickerComponent implements angular.IComponentOptions {
  bindings = {
    paramRequired: '@required',
    paramDisabled: '@disabled',
    paramIncludeLeapYear: '@includeLeapYear',
    minDate: '<',
    maxDate: '<',
  };
  controller = DayMonthPickerController;
  controllerAs = 'vm';
  require = {
    model: 'ngModel',
  };
  templateUrl = 'src/app/_components/common/day-month-picker.component.html';
}

class DayMonthPickerController {
  public paramMonthOnly: string;
  public paramRequired: string;
  public paramIncludeLeapYear: string;
  public paramDisabled: string;
  public minDate: Date;
  public maxDate: Date;
  public model: angular.INgModelController;

  public disabled: boolean;
  public includeLeapYear: boolean = false;
  public required: boolean;
  public month: number;
  public day: number;
  public date: Date;

  public datePickerForm: angular.IFormController;
  public dayPickList: DayInfo[] =
    DateUtils.Locale.getDays().map((day, i) => ({
      display: day,
      value: i + 1,
      allowed: true
    } as DayInfo));

  public monthPickList: MonthInfo[] = [];

  public get dayString(): string {
    return (this.date && this.day != null) ? this.day.toString().padStart(2, '0') : '';
  }

  public get monthString(): string {
    const selectedMonth = this.monthPickList.find((m) => m.monthNumber == this.month);

    return (this.date && selectedMonth) ? selectedMonth.month : '';
  }

  $onInit() {
    this.initialise();
  }

  private initialise() {
    this.model.$parsers.push((value) => {
      if (value) {
        return new Date(value);
      }
    });

    this.model.$render = () => {
      if (this.model.$viewValue != null && typeof this.model.$viewValue == 'string') {
        if (this.model.$viewValue != 'Invalid Date') {
          const dt = new Date(this.model.$viewValue);
          const dateValue = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
          const dirty = this.model.$dirty;
          const parentDirty = (this.model as any).$$parentForm.$dirty;
          this.model.$setViewValue(dateValue);
          this.model.$modelValue = dateValue;
          if (!dirty) this.model.$setPristine();
          if (!parentDirty) (this.model as any).$$parentForm.$setPristine();
        } else {
          this.date = null;
        }
      } else {
        if (this.model.$viewValue != null) {
          const dt: Date = this.model.$viewValue;

          this.date = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
        } else {
          this.date = null;
        }

        this.day = this.date != null ? this.date.getDate() : null;
        this.month = this.date != null ? this.date.getMonth() : null;

        this.setValidity();
        this.setPickLists();
      }
    };
  }

  private setPickLists() {
    const setMonthPickList = () => {
      const shortMonthNames = DateUtils.Locale.getMonthNamesShort();
      const getDaysInMonth = (monthNumber: number) => {
        if (monthNumber === 1) { // February
          return this.includeLeapYear ? 29 : 28;
        }

        // Months with 31 days.
        if ([0, 2, 4, 6, 7, 9, 11].includes(monthNumber)) {
          return 31;
        }

        // Months with 30 days.
        return 30;
      };

      this.monthPickList = Array.from({ length: 24 }, (_, i) => ({
        month: shortMonthNames[i % 12],
        monthNumber: i,
        days: getDaysInMonth(i % 12),
        allowed: true
      }));
    };

    if (!this.monthPickList.length) {
      setMonthPickList();
    }

    // Set 'Allowed' values for the months and days.
    for (let i = 0; i < 24; i++) {
      const testMin = new Date(1970, i, this.minDate ? this.minDate.getDate() : 1);
      const testMax = new Date(1970, i, this.maxDate ? this.maxDate.getDate() : this.monthPickList[i].days);

      this.monthPickList[i].allowed =
        (this.minDate == null || testMin >= this.minDate) && (this.maxDate == null || testMax <= this.maxDate);
    }

    const selectedMonth = this.monthPickList.find((m) => m.monthNumber == this.month);
    const testDate = new Date(1970, 0, 1);

    if (selectedMonth != null) {
      testDate.setMonth(selectedMonth.monthNumber);
    }

    for (let i = 0; i < 31; i++) {
      this.dayPickList[i].allowed =
        selectedMonth != null &&
        this.dayPickList[i].value <= selectedMonth.days &&
        (this.minDate == null || this.minDate <= testDate) &&
        (this.maxDate == null || this.maxDate >= testDate);

      testDate.addDays(1);
    }
  }

  private raiseChange() {
    let newDate: Date;

    if (this.day != null && this.month != null) {
      newDate = new Date(1970, this.month, this.day);
    } else if (this.day == null || this.month == null) {
      newDate = null;
    }

    if (this.date != newDate) {
      this.date = newDate;
      this.model.$setViewValue(this.date);
    }

    this.setPickLists();
    this.setValidity();
  }

  public onDaySelected() {
    if (this.day == undefined) {
      this.day = null;
    }

    this.raiseChange();
  }

  public onMonthSelected() {
    const selectedMonth = this.monthPickList.find((m) => m.monthNumber == this.month);

    if (selectedMonth) {
      if (this.day > selectedMonth.days) {
        this.day = null;
      }
    } else {
      this.month = null;
      this.day = null;
    }

    this.raiseChange();
  }

  private setValidity() {
    if (this.datePickerForm != null && this.model != null && !this.disabled) {
      if (this.minDate != null && this.date != null) {
        this.datePickerForm['Select_Day'].$setValidity('min', this.date >= this.minDate);
        this.datePickerForm['Select_Month'].$setValidity('min', this.date >= this.minDate);
        this.model.$setValidity('min', this.date >= this.minDate);
      } else {
        this.datePickerForm['Select_Day'].$setValidity('min', true);
        this.datePickerForm['Select_Month'].$setValidity('min', true);
        this.model.$setValidity('min', true);
      }

      if (this.maxDate != null && this.date != null) {
        this.datePickerForm['Select_Day'].$setValidity('max', this.date <= this.maxDate);
        this.datePickerForm['Select_Month'].$setValidity('max', this.date <= this.maxDate);
        this.model.$setValidity('max', this.date <= this.maxDate);
      } else {
        this.datePickerForm['Select_Day'].$setValidity('max', true);
        this.datePickerForm['Select_Month'].$setValidity('max', true);
        this.model.$setValidity('max', true);
      }

      if (
        this.required &&
        this.date == null &&
        (this.datePickerForm['Select_Day'].$touched || this.datePickerForm['Select_Month'].$touched)
      ) {
        this.datePickerForm['Select_Month'].$setTouched();
        this.datePickerForm['Select_Day'].$setTouched();
        this.datePickerForm['Select_Month'].$setValidity('required', false);
        this.datePickerForm['Select_Day'].$setValidity('required', false);
        this.model.$setValidity('required', false);
      } else {
        this.datePickerForm['Select_Month'].$setValidity('required', true);
        this.datePickerForm['Select_Day'].$setValidity('required', true);
        this.model.$setValidity('required', true);
      }
    }
  }

  $onChanges(changes) {
    if (changes.paramDisabled) {
      switch (typeof this.paramDisabled) {
        case 'string': {
          this.disabled = this.paramDisabled === 'true' || this.paramDisabled === '';
          break;
        }
        case 'undefined': {
          this.disabled = false;
          break;
        }
        case 'boolean': {
          this.disabled = this.paramDisabled;
          break;
        }
      }
    }

    if (changes.paramRequired) {
      switch (typeof this.paramRequired) {
        case 'string': {
          this.required = this.paramRequired === 'true' || this.paramRequired === '';
          break;
        }
        case 'undefined': {
          this.required = false;
          break;
        }
        case 'boolean': {
          this.required = this.paramRequired;
          break;
        }
      }
    }

    if (changes.paramIncludeLeapYear) {
      switch (typeof this.paramIncludeLeapYear) {
        case 'string': {
          this.includeLeapYear = this.paramIncludeLeapYear === 'true' || this.paramIncludeLeapYear === '';
          break;
        }
        case 'undefined': {
          this.includeLeapYear = false;
          break;
        }
        case 'boolean': {
          this.includeLeapYear = this.paramIncludeLeapYear;
        }
      }
    }

    if (changes.maxDate) {
      if (this.maxDate == null) {
        //set default maxDate
        this.maxDate = new Date(1970, 11, 31); //Dec 31
      } else {
        this.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
        //check our existing values are still valid
        if (this.date != null) {
          if (this.date > this.maxDate) {
            this.day = null;
            this.month = null;
          }
        }
      }
    }

    if (changes.minDate) {
      if (this.minDate == null) {
        //set default minDate
        this.minDate = new Date(1970, 0, 1); //Jan 1
      } else {
        this.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate());
        //check our existing values are still valid
        if (this.date != null) {
          if (this.date < this.minDate) {
            this.day = null;

            if (this.date.getMonth() != this.minDate.getMonth()) {
              this.month = null;
            }
          }
        }
      }
    }

    if (changes.minDate || changes.maxDate || changes.paramIncludeLeapYear) {
      this.setPickLists();
    }

    this.setValidity();
  }
}

angular.module('fuse').component('dayMonthPicker', new DayMonthPickerComponent());
