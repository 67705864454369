import { breezeClass } from '@common/models/baseClass';

export class WaterBudgetMonth extends breezeClass implements breeze.Entity {
  Id: number;
  BudgetId: number;
  dayNumber: number;
  MonthStartDate: Date;
  ThisYearEToPct: number;
  ThisYearRainPct: number;
  OriginalCropCoefficient: number;
  TargetCropCoefficient: number;
  VolumeCapML: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  ManualEto?: number;
  ManualRain?: number;
  VolumeCapKL?: number;
}
