import * as angular from 'angular';
import { NumberUtils } from '@indicina/swan-shared/utils/NumberUtils';

angular.module('fuse').directive('decimalStep', () => {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      step: '@',
      maxlength: '@',
    },
    link: (scope: angular.IScope, element: JQLite, attrs: angular.IAttributes, ngModel: any) => {
      const step: string = scope['step'];

      if (!step) {
        return;
      }

      let decimals = 0;
      const decimalIndex: number = step.indexOf('.');

      if (decimalIndex > 0) {
        decimals = step.substring(decimalIndex + 1).length;
      }

      // NOTE: Not likely to be a problem, however note that the floating numbers
      // with more than 15 decimals could cause issues with the rounding!
      const convertValue = (value: string): string => {
        return NumberUtils.round(Number(value), decimals).toFixed(decimals); // NOTE: 'toFixed(decimals)' ensures decimal places are always displayed (even when *.00).
      };

      const setElementAndModelValue = (convertedValue: string, originalValue: string) => {
        element.val(convertValue(convertedValue));
        ngModel.$setViewValue(originalValue); // IMPORTANT: This is required to ensure model value is updated with a value rather than 'null'.
      };

      ngModel.$render = () => {
        if (ngModel.$modelValue == null) {
          return;
        }

        const value = ngModel.$modelValue;
        const convertedValue = convertValue(value);

        setElementAndModelValue(convertedValue, value.toString());
      };

      element.on('keypress', (e) => {
        if (decimals === 0 && e.which === 46) {
          // 46 is decimal point
          e.preventDefault();
        }

        if (scope['maxlength'] && (element.val().toString()).length >= scope['maxlength']) {
          e.preventDefault();
        }
      });

      element.on('keyup', (e) => {
        const originalValue = element.val().toString();
        const convertedValue = convertValue(originalValue);

        if (Number(originalValue) !== Number(convertedValue)) {
          setElementAndModelValue(convertedValue, originalValue);
        } else if (ngModel.$modelValue == null) {
          ngModel.$setViewValue(originalValue);
        }
      });
    },
  };
});
