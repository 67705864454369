import * as angular from 'angular';

angular.module('fuse').directive('rangeValidator', () => {
  return {
    require: 'ngModel',
    link: (scope: angular.IScope, element: JQLite, attrs: angular.IAttributes, ngModel: any) => {
      ngModel.$validators.range = (val: string | number) => {
        const min = parseFloat(attrs.minValue);
        const max = parseFloat(attrs.maxValue);
        const value = parseFloat(val.toString());

        return value >= min && value <= max;
      };
    },
  }
});
