import * as moment from 'moment';
import { Asset } from './Asset';
import { AuthUser } from './AuthUser';
import { WorkAgent } from './WorkAgent';
import { breezeClass } from '@common/models/baseClass';

export class ObsWeatherRollup extends breezeClass implements breeze.Entity {
  /// <code> Place custom code between <code> tags
  ManualDateTime: Date;
  private _localDate?: Date;

  get localDate(): Date {
    if (this._localDate == null && this.dayDisplayYMD != null && this.dayDisplayYMD != 'Invalid date') {
      this._localDate = moment(this.dayDisplayYMD, 'YYYY-MM-DD').toDate();
    }
    return this._localDate;
  }

  set localDate(date: Date) {
    this._localDate = date;
    this.dayDisplayYMD = moment(date).format('YYYY-MM-DD');
    this.dayNumber = -999;
  }
  /// </code>

  public toggleLock() {
    this.Locked = !this.Locked;
  }

  // Generated code. Do not place code below this line.
  Id: number;
  Status: string;
  Since: Date;
  EToShort: number;
  EToLong: number;
  Rainfall: number;
  PanEvaporation: number;
  TemperatureMax: number;
  TemperatureMin: number;
  TemperatureMean: number;
  HumidityMax: number;
  HumidityMin: number;
  HumidityMean: number;
  WindSpeedMean: number;
  SolarRadiation: number;
  AssetId: number;
  dayDate: Date;
  dayNumber: number;
  dayDisplayMMdd: string;
  dayDisplayYMD: string;
  dayOfWeek: string;
  QualityFactor: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
  Locked: boolean;
  Asset: Asset;
  AuthUser: AuthUser;
  WorkAgent: WorkAgent;
}
