import { breezeClass } from '@common/models/baseClass';

export class WaterBudgetSiteMonth extends breezeClass implements breeze.Entity {
  BudgetId: number;
  AssetId: number;
  DayNumber: number;
  Water_KL_Budget: number;
  Water_KL_Override: number;
  TrackCreatedWhen: Date;
  TrackCreatedBy: string;
  TrackModifiedWhen: Date;
  TrackModifiedBy: string;
  TrackDeletedWhen: Date;
  TrackDeletedBy: string;
  WorkAgentId: number;
}
