import * as angular from 'angular';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';

angular.module('fuse').directive('unitConverter', (UnitOfMeasureService: UnitOfMeasureService) => {
  return {
    require: 'ngModel',
    link: (scope, element, attrs, ngModel: any) => {
      const unitType = attrs.unitType;
      const unitSize = attrs.unitSize;
      const unit = UnitOfMeasureService.getUnits(unitType, unitSize);
      const unitDecimal = attrs.unitDecimal ?? unit.decimalPlaces;

      ngModel.$parsers.unshift((viewValue) => {
        return unit.toBaseWithNulls(viewValue);
      });

      //Convert from base <
      ngModel.$formatters.unshift((modelValue) => {
        return unit.fromBaseRounded(modelValue, unitDecimal);
      });

      UnitOfMeasureService.setListeners(element, unitDecimal);
    },
  };
});
