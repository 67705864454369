import * as angular from 'angular';
import { unitSizes } from '@common/enums';
import { DayNumberService } from '@services/day-number.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';
import { PermissionService } from '@services/permission.service';

class WeatherSummaryTableComponent implements angular.IComponentOptions {
  bindings = {
    weatherSummary: '<',
  };
  controller = WeatherSummaryTableController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/weather-summary-table.html';
}

class WeatherSummaryTableController extends BaseController {
  private weatherSummary: fuse.dashboardWeatherItem[];
  public displayData: fuse.dashboardWeatherItem[];
  public rainfallNormalUnit: string;
  public etoNormalUnit: string;
  public temperatureNormalUnit: string;
  public windspeedNormalUnit: string;
  public windspeedRangeLow: number;
  public windspeedRangeHigh: number;
  public adjustedTodayDayNumber: number;

  constructor(
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this.adjustedTodayDayNumber = DayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    this.rainfallNormalUnit = UnitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
    this.etoNormalUnit = UnitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);
    this.temperatureNormalUnit = UnitOfMeasureService.getUnitLabel('Temperature', unitSizes.normal);
    this.windspeedNormalUnit = UnitOfMeasureService.getUnitLabel('Velocity', unitSizes.normal);
  }

  $onChanges(changes) {
    if (changes.weatherSummary?.currentValue) {
      this.displayData = this.weatherSummary.map((weather) => {
        return {
          dayNumber: weather.dayNumber,
          localeDate: weather.localeDate,
          rainfall: weather.rainfall,
          eto: weather.eto,
          maxTemp: weather.maxTemp,
          minTemp: weather.minTemp,
          maxHumidity: weather.maxHumidity,
          minHumidity: weather.minHumidity,
          windSpeed: weather.windSpeed,
        } as fuse.dashboardWeatherItem;
      });
    }
  }
}

angular.module('fuse').component('weatherSummaryTable', new WeatherSummaryTableComponent());
