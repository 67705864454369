import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { AmGraphDataValues, UnitTypes, unitSizes } from '@common/enums';
import { EntityListService } from '@services/entity-list.service';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';

export class WSMDashboardComponent implements angular.IComponentOptions {
  bindings = {
    refreshCount: '<',
  };
  controller = WSMDashboardController;
  controllerAs = 'vm';
  templateUrl = 'src/app/_components/wsm-dashboard.html';
}

class WSMDashboardController extends BaseController {
  public volumeNormalUnit: uomUnit;
  public volumeLargeUnit: uomUnit;
  public hasData: boolean;
  public groupsWithInfra = [];
  public groupsForAllocs: fuse.wSMGroupSummaryDto[];
  public selectedGroupId: number | null = null;

  private _entityListService: EntityListService;
  private _languageService: LanguageService;
  private _localStorageService: LocalStorageService;

  private _chartColours = [
    '#FF8800',
    '#B0DE09',
    '#FCD202',
    '#CC0000',
    '#0000FF',
    '#FF66FF',
    '#8800FF',
    '#888888',
    '#880220',
    '#000000',
  ];
  private _totalAllocations = 'total';
  private _budgetCol = 'BUDGET';
  private _allAllocsCol = 'ALL';
  private _volumeHugeUnit: uomUnit;
  private _allocationData: any[];
  private _usageData: fuse.wSMAllocUsageDto[];

  constructor(
    $scope: angular.IScope,
    EntityListService: EntityListService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._entityListService = EntityListService;
    this._languageService = LanguageService;
    this._localStorageService = LocalStorageService;

    this.volumeNormalUnit = UnitOfMeasureService.getUnits(UnitTypes.Volume, unitSizes.normal);
    this.volumeLargeUnit = UnitOfMeasureService.getUnits(UnitTypes.Volume, unitSizes.large);
    this._volumeHugeUnit = UnitOfMeasureService.getUnits(UnitTypes.Volume, unitSizes.huge);
  }

  $onChanges(changes) {
    if (changes.refreshCount) {
      this.groupsWithInfra = [];
      this.groupsForAllocs = [];
      this.selectedGroupId = null;
      this.hasData = false;

      const loadSummary = (): void => {
        const afterFunc = (res: fuse.wSMGroupSummaryDto[]) => {
          const total = res.find(a => a.name == 'ALLOCATION.ACCOUNT_TOTAL');

          if (total != null){
            total.name = this._languageService.instant(total.name);
          }

          this.groupsForAllocs = res.filter((gs) => gs.id > 0); // id=0 used for account total row
          this.groupsWithInfra = res.filter((gs) => gs.volumeKL != null || gs.available != 0); // exclude from table where no infrastructure exists

          const selectedGroupId = this._localStorageService.getWsmGroupId();

          if (selectedGroupId != this.selectedGroupId) {
            this.selectedGroupId = selectedGroupId;
            this.groupChange(selectedGroupId);
          }
        };

        this._entityListService.getEntityList('infrastructure/getWSMSummary', afterFunc, false);
      };

      loadSummary();
    }
  }

  public groupChange(groupId: number) {
    if (!groupId) {
      return;
    }

    const loadAllocations = (groupId: number): void => {
      const afterFunc = (result: fuse.wSMAllocationSummaryDto) => {
        this.hasData = !!result.dataByAlloc?.length || !!result.dataByDate?.length;

        if (this.hasData) {
          this._allocationData = result.dataByDate as any;
          this._usageData = result.dataByAlloc ?? [];
          this._allocationData['hasBudget'] = ArrayUtils.sum(this._allocationData.map((s) => s[this._budgetCol] as number)) > 0;

          this.add100PercLine();
          this.drawUsagePlot();
          this.drawPerformancePlot();
        }
      };

      this._entityListService.getEntityList('group/allocationsForGroup', afterFunc, false, { groupId: groupId });
    };

    loadAllocations(groupId);

    this._localStorageService.setWsmGroupId(groupId);
  }

  private add100PercLine() {
    this._allocationData.forEach((day) => {
      day[this._totalAllocations] = 100;
    });
  }

  // WSM dashboard bar graph for usage by allocation
  private drawUsagePlot() {
    const xField = 'name';
    const underAlloc = 'under-allocation';
    const overAlloc = 'over-allocation';
    const overBudget = 'over-budget';

    // Separate usage data into under allocation, over allocation and over budget categories
    const dataProvider = this._usageData.map((alloc) => {
      const data = {
        name: alloc.name,
        allocation: alloc.allocationTotal,
        budget: alloc.budget,
      };

      let category = underAlloc;

      if (alloc.budget > 0 && alloc.usageTotal > alloc.budget) {
        category = overBudget;
      }

      if (alloc.usageTotal > alloc.allocationTotal) {
        category = overAlloc;
      }

      data[category] = alloc.usageTotal;

      return data;
    });

    const fields = ['budget', 'allocation', underAlloc, overAlloc, overBudget];

    dataProvider.forEach((alloc) => {
      fields.forEach((field) => {
        alloc[field] = this._volumeHugeUnit.fromBaseRounded(alloc[field]);
      });
    });

    const widthMult = Math.min(dataProvider.length, 5);
    const largeWidth = 0.2 * widthMult;
    const smallWidth = 0.12 * widthMult;

    const volumeAxis = {
      dashLength: 1,
      gridThickness: 0,
      id: 'volumeAxis',
      minimum: 0,
      position: 'left',
      strictMinMax: true,
      title: this._volumeHugeUnit.name,
    } as AmCharts.ValueAxis;

    const getAllocationGraph = (): AmCharts.AmGraph => {
      const allocationGraph = {
        clustered: false,
        columnWidth: largeWidth,
        fillAlphas: 1,
        fillColors: '#30398a',
        lineColor: '#5f5f5f',
        lineThickness: 1,
        title: this._languageService.instant('ALLOCATION.ALLOCATION'),
        type: 'column',
        valueAxis: volumeAxis,
        valueField: 'allocation',
        balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
          const val = (graphDataItem.values as AmGraphDataValues).value;
          if (val) {
            const str = this._languageService.instant('ALLOCATION.TOTAL_ALLOCATION');
            return `${str}: <br> ${val} ${this._volumeHugeUnit.name}`;
          } else {
            return '';
          }
        },
      } as AmCharts.AmGraph;

      return allocationGraph;
    };

    const getBudgetGraphs = (): AmCharts.AmGraph[] => {
      if (!this._allocationData['hasBudget']) {
        return [];
      }

      const budgetGraph = {
        clustered: false,
        columnWidth: largeWidth,
        fillAlphas: 1,
        fillColors: '#9bcff9',
        lineColor: '#5f5f5f',
        lineThickness: 1,
        title: this._languageService.instant('COMMON.BUDGET'),
        type: 'column',
        valueAxis: volumeAxis,
        valueField: 'budget',
        balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
          const val = (graphDataItem.values as AmGraphDataValues).value;
          if (val) {
            const str = this._languageService.instant('ALLOCATION.BUDGET_TO_DATE');
            return `${str}: <br> ${val} ${this._volumeHugeUnit.name}`;
          } else {
            return '';
          }
        },
      } as AmCharts.AmGraph;

      const overBudgetGraph = {
        valueField: overBudget,
        type: 'column',
        columnWidth: smallWidth,
        lineThickness: 1,
        lineColor: '#5f5f5f',
        valueAxis: volumeAxis,
        fillAlphas: 1,
        clustered: false,
        title: this._languageService.instant('ALLOCATION.OVER_BUDGET'),
        fillColors: '#ffc107',
        balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
          const val = (graphDataItem.values as AmGraphDataValues).value;
          if (val) {
            const used = this._languageService.instant('ALLOCATION.USED');
            const str = this._languageService.instant('ALLOCATION.OVER_BUDGET_TO_DATE');
            return `${used}: ${val} ${this._volumeHugeUnit.name}<br>(${str})`;
          } else {
            return '';
          }
        },
      } as AmCharts.AmGraph;

      return [budgetGraph, overBudgetGraph];
    };

    const getUnderAllocationGraph = (): AmCharts.AmGraph => {
      const underAllocationGraph = {
        clustered: false,
        columnWidth: smallWidth,
        fillAlphas: 1,
        fillColors: '#00c853',
        lineColor: '#5f5f5f',
        lineThickness: 1,
        title: this._languageService.instant('ALLOCATION.WITHIN_ALLOCATION'),
        type: 'column',
        valueAxis: volumeAxis,
        valueField: underAlloc,
        balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
          const val = (graphDataItem.values as AmGraphDataValues).value;
          if (val) {
            const used = this._languageService.instant('ALLOCATION.USED');
            const str = this._languageService.instant('ALLOCATION.WITHIN_ALLOCATION');
            return `${used}: ${val} ${this._volumeHugeUnit.name}<br>(${str})`;
          } else {
            return '';
          }
        },
      } as AmCharts.AmGraph;

      return underAllocationGraph;
    };

    const getOverAllocationGraph = (): AmCharts.AmGraph => {
      const overAllocationGraph = {
        clustered: false,
        columnWidth: smallWidth,
        fillAlphas: 1,
        fillColors: '#e57373',
        lineColor: '#5f5f5f',
        lineThickness: 1,
        title: this._languageService.instant('ALLOCATION.OVER_ALLOCATION'),
        type: 'column',
        valueAxis: volumeAxis,
        valueField: overAlloc,
        balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
          const val = (graphDataItem.values as AmGraphDataValues).value;
          if (val) {
            const used = this._languageService.instant('ALLOCATION.USED');
            const str = this._languageService.instant('ALLOCATION.OVER_ALLOCATION');
            return `${used}: ${val} ${this._volumeHugeUnit.name}<br>(${str})`;
          } else {
            return '';
          }
        },
      } as AmCharts.AmGraph;

      return overAllocationGraph;
    };

    const maxLabelCharCount = ArrayUtils.max(dataProvider.map((d) => d.name.length));
    const labelRotation = maxLabelCharCount > 20 ? 15 : 0;

    const chartOptions = {
      categoryAxis: {
        gridPosition: 'center',
        labelRotation: labelRotation,
        position: 'bottom',
      },
      categoryField: xField,
      dataProvider: dataProvider,
      graphs: [
        getAllocationGraph(),
        ...getBudgetGraphs(),
        getUnderAllocationGraph(),
        getOverAllocationGraph(),
      ],
      legend: {
        useGraphSettings: true,
        position: 'bottom',
        valueText: '',
      } as AmCharts.AmLegend,
      theme: 'light',
      type: 'serial',
      valueAxes: [volumeAxis] as AmCharts.ValueAxis[],
    } as AmCharts.AmSerialChart;

    AmCharts.makeChart('wsm-usage-chart', chartOptions) as AmCharts.AmSerialChart;
  }

  // WSM dashboard line graph for allocation % usage by date
  private drawPerformancePlot() {
    const xField = 'dayDisplayYMD';
    const dataProvider = this._allocationData ?? [];

    const firstRow = dataProvider.length ? dataProvider[0] : [];
    const allocationNames = Object.keys(firstRow).filter((k) => ![xField, 'dayNumber', this._budgetCol, this._allAllocsCol].includes(k));
    const graphMax = 1.2 * ArrayUtils.max(this._allocationData.map((a) => ArrayUtils.max(allocationNames.map((n) => Math.max(a[n], a[this._budgetCol])))));

    const percentAxis = {
      id: 'percentAxis',
      dashLength: 1,
      gridAlpha: 0.5,
      title: this._languageService.instant('ALLOCATION.USAGE_AS_PERC'),
      position: 'left',
      maximum: graphMax,
      strictMinMax: true,
    } as AmCharts.ValueAxis;

    const getAllocationGraphs = (): AmCharts.AmGraph[] => {
      return allocationNames.map((allocationName, i) => {
        const key = allocationName;

        // Try and give every alloc a different colour. If more allocations in group than we have colours defined, leave colour=null and let AmCharts set colours automatically
        const colour = key != this._totalAllocations ? this._chartColours?.[i] ?? null : '#78ACDD';

        return {
          bullet: 'round',
          bulletAlpha: 0,
          lineAlpha: 1,
          lineColor: colour,
          lineThickness: 3,
          title: key !== this._totalAllocations ? key : this._languageService.instant('ALLOCATION.TOTAL_ALLOCATION'),
          type: 'line',
          valueAxis: percentAxis,
          valueField: key,
        } as AmCharts.AmGraph;
      });
    };

    const getBudgetGraph = (): AmCharts.AmGraph | null => {
      if (!dataProvider['hasBudget']) {
        return null;
      }

      const budgetGraph = {
        bullet: 'round',
        bulletAlpha: 0,
        dashLength: 10,
        lineAlpha: 1,
        lineColor: '#000000',
        lineThickness: 3,
        title: this._languageService.instant('ALLOCATION.TOTAL_BUDGET'),
        type: 'line',
        valueAxis: percentAxis,
        valueField: this._budgetCol,
      } as AmCharts.AmGraph;

      return budgetGraph;
    };

    const getAllAllocationsGraph = (): AmCharts.AmGraph | null => {
      if (allocationNames.length < 3) {
        return null;
      }

      // Show all-allocations column if we have 2+ allocations (+ 100% total alloc line)
      const allAllocationsGraph = {
        bullet: 'round',
        bulletAlpha: 0,
        dashLength: 3,
        lineAlpha: 1,
        lineColor: '#0070C0',
        lineThickness: 4,
        title: this._languageService.instant('ALLOCATION.ALL_ALLOCATIONS'),
        type: 'line',
        valueAxis: percentAxis,
        valueField: this._allAllocsCol,
      } as AmCharts.AmGraph;

      return allAllocationsGraph;
    };

    const chartOptions = {
      categoryAxis: {
        gridPosition: 'center',
        labelRotation: 0,
        position: 'bottom',
        gridAlpha: 0,
      },
      categoryField: xField,
      dataProvider: dataProvider,
      graphs: [
        ...getAllocationGraphs(),
        getBudgetGraph(),
        getAllAllocationsGraph(),
      ].filter((x) => x), // Filter any 'falsy' values.
      legend: {
        useGraphSettings: true,
        position: 'bottom',
        valueText: '',
      } as AmCharts.AmLegend,
      theme: 'light',
      type: 'serial',
      valueAxes: [percentAxis] as AmCharts.ValueAxis[],
    } as AmCharts.AmSerialChart;

    AmCharts.makeChart('wsm-performance-chart', chartOptions) as AmCharts.AmSerialChart;
  }
}

angular.module('fuse').component('wsmDashboard', new WSMDashboardComponent());
